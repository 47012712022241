import { Box, Typography } from "@mui/material";
import React from "react";
import LockIcon from "@mui/icons-material/Lock";
import IconButton from "../../components/IconBtn/IconBtn";
import { useNavigate } from "react-router-dom";

const ApprovedSign = () => {
  const navigate = useNavigate();
  return (
    <Box>
      <Typography
        variant="body2"
        sx={{
          fontSize: "24px",
          textAlign: "center",
          fontWeight: "500",
          mb: 1,
          color: "#4d4d4d",
        }}
      >
        Awesome, you're finished!
      </Typography>
      <Typography
        variant="body2"
        sx={{ fontSize: "14px", textAlign: "center", mb: 2, color: "#838588" }}
      >
        Your loan has been approved
      </Typography>

      <Typography
        variant="body2"
        sx={{ fontSize: "14px", color: "#838588", mt: 4 }}
      >
        Funds are typically available the next business day if your loan is
        approved before 5pm MT! If you’ve selected debit card funding, they are
        typically available within a few hours if your loan is approved within
        our operating hours.
      </Typography>
      <Box display="flex" justifyContent="center" mt={16}>
        <IconButton
          icon={<LockIcon sx={{ fontSize: "18px", color: "#00db8f" }} />}
          handleBtnClick={() => {
            navigate("/Portal");
          }}
          title="RETURN HOME"
          type="contained"
        />
      </Box>
    </Box>
  );
};

export default ApprovedSign;
