import {
  Grid,
  Typography,
  TextField,
  FormControlLabel,
  Checkbox,
  Link,
} from "@mui/material";
import React from "react";
import Dropdown, { DropdownOption } from "../../components/common/Dropdown";
import Recaptcha from "../../components/recaptcha/Recaptcha";
import Constants from "../../constants/constant";
import ConsentEmailSMSModal from "../Modals/ConsentEmailSMSModal";
import PrivacyPolicyModal from "../Modals/PrivacyPolicyModal";
import TermsOfUseModal from "../Modals/TermsOfUseModal";
import { LoanApplication } from "../../services/leadsService";

type Props = {
  advertisingMethods: DropdownOption[];
  email: string;
  loanApplicationData: LoanApplication;
  setCaptchaToken: (value: string) => void;
  handleFieldChange: (field: string, value: any) => void;
  errors: any;
};

const terms = [
  "I expressly authorize Lift Credit and its affiliates to share among them any transaction history related to my financial products or services received through or serviced by Lift Credit for the purpose of evaluating me for credit.",
  "I hereby give permission to verify the information provided in this application for a credit decision, which may include contacting employers, personal references, and obtaining consumer reports at any time for any purpose.",
  "If your loan application doesn’t meet our minimum requirements, Lift Credit may forward your information to a partner who can help find a lender that may better fit your situation.",
];

const LoanAppStep5 = (props: Props) => {
  const {
    advertisingMethods,
    email,
    loanApplicationData,
    setCaptchaToken,
    handleFieldChange,
    errors,
  } = props;

  const [termsOpen, setTermsOpen] = React.useState(false);
  const [policyOpen, setPolicyOpen] = React.useState(false);
  const [emailConsentOpen, setEmailConsentOpen] = React.useState(false);

  const onLinkClick = (url: string) => {
    window.open(url, "_blank");
  };

  return (
    <>
      {" "}
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Dropdown
            id="advertisingMethod"
            label="How did you hear about us?"
            value={loanApplicationData?.advertisingMethod}
            options={advertisingMethods}
            onChange={(val) => handleFieldChange("advertisingMethod", val)}
            required={true}
            error={errors.advertisingMethod}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="h6"
            sx={{
              fontSize: "14px",
              fontWeight: "600",
              paddingLeft: "8px",
              marginBottom: "5px",
              color: "#838588",
            }}
          >
            Promo Code (Optional)
          </Typography>
          <TextField
            variant="outlined"
            value={loanApplicationData?.loanRequest?.promoCode}
            onChange={(e) => handleFieldChange("promoCode", e.target.value)}
            fullWidth
            size="small"
            placeholder="Enter Promo Code (Optional)"
          />
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="h6"
            sx={{
              fontSize: "14px",
              paddingLeft: "8px",
              marginBottom: "5px",
              color: "#838588",
              fontWeight: "400",
            }}
          >
            An account with the username: <b>{email}</b> will be created for
            your personal loan portal where you will be able to check the status
            of your loan, redeem rewards, and make loan payments once your loan
            is approved and active.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="h6"
            sx={{
              fontSize: "14px",
              fontWeight: "600",
              paddingLeft: "8px",
              marginBottom: "5px",
              color: "#838588",
            }}
          >
            Verification
          </Typography>
          <Recaptcha setCaptchaToken={setCaptchaToken} />
          {errors.captcha && (
            <Typography color="error" variant="caption">
              {errors.captcha}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="h6"
            sx={{
              fontSize: "14px",
              fontWeight: "600",
              paddingLeft: "8px",
              marginBottom: "5px",
              marginTop: "20px",
              color: "#838588",
            }}
          >
            By clicking "Apply Now" you agree to the following:
          </Typography>
          <ul>
            <li>
              <Typography
                variant="h6"
                sx={{
                  fontSize: "14px",
                  fontWeight: "400",
                  paddingLeft: "8px",
                  color: "#838588",
                }}
              >
                I consent to Lift Credit's{" "}
                {
                  <Link
                    href="#"
                    underline="always"
                    sx={{
                      cursor: "pointer",
                      color: "#00db8f",
                      "&:hover": {
                        textDecoration: "underline",
                        color: "#00db8f",
                      },
                    }}
                    onClick={() => setTermsOpen(true)}
                  >
                    Terms of Use
                  </Link>
                }{" "}
                and{" "}
                {
                  <Link
                    href="#"
                    underline="always"
                    sx={{
                      cursor: "pointer",
                      color: "#00db8f",
                      "&:hover": {
                        textDecoration: "underline",
                        color: "#00db8f",
                      },
                    }}
                    onClick={() => setPolicyOpen(true)}
                  >
                    Privacy Policy
                  </Link>
                }
                .
              </Typography>
            </li>
            <li>
              <Typography
                variant="h6"
                sx={{
                  fontSize: "14px",
                  fontWeight: "400",
                  paddingLeft: "8px",
                  color: "#838588",
                }}
              >
                I consent to receive calls (including SMS text messages) from
                Lift Credit regarding any account as specified{" "}
                {
                  <Link
                    href="#"
                    underline="always"
                    sx={{
                      cursor: "pointer",
                      color: "#00db8f",
                      "&:hover": {
                        textDecoration: "underline",
                        color: "#00db8f",
                      },
                    }}
                    onClick={() => setEmailConsentOpen(true)}
                  >
                    here
                  </Link>
                }
                .
              </Typography>
            </li>
            {terms.map((term, index) => (
              <li key={index}>
                <Typography
                  variant="h6"
                  sx={{
                    fontSize: "14px",
                    fontWeight: "400",
                    paddingLeft: "8px",
                    color: "#838588",
                  }}
                >
                  {term}
                </Typography>
              </li>
            ))}
          </ul>
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="h6"
            sx={{
              fontSize: "14px",
              fontWeight: "600",
              paddingLeft: "8px",
              marginBottom: "5px",
              marginTop: "20px",
              color: "#838588",
            }}
          >
            SMS Consent:
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            sx={{
              display: "flex",
              alignItems: "center",
            }}
            control={
              <Checkbox
                checked={loanApplicationData?.optInToReceiveTextMessage}
                onChange={(e) =>
                  handleFieldChange(
                    "optInToReceiveTextMessage",
                    e.target.checked
                  )
                }
                name="optInToReceiveTextMessage"
                color="primary"
                sx={{
                  padding: "0px 9px",
                  borderRadius: "5px",
                  color: "#838588",
                  "&.Mui-checked": {
                    color: "#838588",
                  },
                }}
              />
            }
            label={
              <Typography
                variant="body2"
                sx={{
                  fontSize: "12px",
                  color: "#838588",
                }}
              >
                Opt-In to receive SMS account text messages such as payment
                reminders.*
              </Typography>
            }
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            sx={{
              display: "flex",
              alignItems: "center",
            }}
            control={
              <Checkbox
                checked={loanApplicationData?.optInToMarketingMessage}
                onChange={(e) =>
                  handleFieldChange("optInToMarketingMessage", e.target.checked)
                }
                name="optInToMarketingMessage"
                color="primary"
                sx={{
                  padding: "0px 9px",
                  borderRadius: "5px",
                  color: "#838588",
                  "&.Mui-checked": {
                    color: "#838588",
                  },
                }}
              />
            }
            label={
              <Typography
                variant="body2"
                sx={{
                  fontSize: "12px",
                  color: "#838588",
                }}
              >
                Opt-In to receive marketing messages. OPTING-IN FOR MARKETING
                MESSAGES IS NOT A REQUIREMENT TO RECEIVE THE SERVICES YOU'VE
                REQUESTED FROM Lift Credit.*
              </Typography>
            }
          />
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="h6"
            sx={{
              fontSize: "14px",
              fontWeight: "400",
              paddingLeft: "8px",
              color: "#838588",
            }}
          >
            *Message and data rates may apply. Message frequency will vary. To
            opt-out at any time, reply STOP to any message we send, or HELP for
            more information.
            {
              <Link
                href="#"
                underline="none"
                sx={{
                  cursor: "pointer",
                  color: "#00db8f",
                  "&:hover": {
                    color: "#00db8f",
                  },
                }}
                onClick={() =>
                  onLinkClick(Constants.infoLiftCredit.textMessagingTermsUrl)
                }
              >
                Text Messaging Terms
              </Link>
            }{" "}
            and{" "}
            {
              <Link
                href="#"
                underline="none"
                sx={{
                  cursor: "pointer",
                  color: "#00db8f",
                  "&:hover": {
                    color: "#00db8f",
                  },
                }}
                onClick={() => onLinkClick(Constants.infoLiftCredit.privacyUrl)}
              >
                Privacy Policy
              </Link>
            }
            .
          </Typography>
        </Grid>
      </Grid>
      <TermsOfUseModal
        open={termsOpen}
        handleClose={() => setTermsOpen(false)}
      />
      <PrivacyPolicyModal
        open={policyOpen}
        handleClose={() => setPolicyOpen(false)}
      />
      <ConsentEmailSMSModal
        open={emailConsentOpen}
        handleClose={() => setEmailConsentOpen(false)}
      />
    </>
  );
};

export default LoanAppStep5;
