import { Backdrop, Box } from "@mui/material";
import React from "react";
import Loader from "../loader/Loader";

type Props = {
  loading: boolean;
  message?: string;
};

const BackDrop = (props: Props) => {
  const { loading, message } = props;
  return (
    <Backdrop
      open={loading}
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
        backgroundColor: "rgba(255, 255, 255, 0.5)",
        backdropFilter: "blur(5px)",
        position: "absolute",
        top: "64px",
        left: 0,
        width: "100%",
        height: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "600px",
        }}
      >
        <Loader message={message ?? ""} />
      </Box>
    </Backdrop>
  );
};

export default BackDrop;
