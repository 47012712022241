import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Typography } from "@mui/material";
import {
  getBankVerificationFailedAttempts,
  getBankVerificationRequestCode,
  getBankVerificationRequestCodeStatus,
  getChirpLinkToken,
  getLokyataScoreData,
} from "../../services/bankVerificationService";
import BackDrop from "../../components/common/BackDrop";
import ChirpLink from "./ChirpLink";
import { getCustomerStatus } from "../../services/customerService";

declare global {
  interface Window {
    ChirpLink: any;
  }
}

const BankVerificationProcess = () => {
  const [pendingLoan, setPendingLoan] = useState<any | null>(null);
  const [requestCodeDetails, setRequestCodeDetails] = useState<{
    requestCode: string;
    iframeUrl: string;
  } | null>(null);
  const [transition, setTransition] = useState<string | null>(null);
  const [bankVerificationAttemptCount, setBankVerificationAttemptCount] =
    useState<number>(0);
  const [loaderMessage, setLoaderMessage] = useState<string | null>(null);
  const [chirpLinkToken, setChirpLinkToken] = useState<string | null>(null);
  const [openIframe, setOpenIframe] = useState(false);
  const [loading, setLoading] = useState(false);
  const [lokyataScore, setLokyataScore] = useState<{
    scoreAvailable: boolean;
    loanDenied: boolean;
    manualReview: boolean;
  } | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    getLoanData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const script = document.createElement("script");

    script.src = "https://chirp.digital/cdn/chirplink.js";
    script.async = true;

    document.body.appendChild(script);
    script.onload = () => {
      setTimeout(() => {
        console.log("Available window keys:", Object.keys(window));
        if (window.ChirpLink) {
          console.log("ChirpLink loaded successfully.");
          const chirpInstance = new window.ChirpLink({
            token: "your-token-here",
            mode: "test",
            onLoad: () => console.log("ChirpLink initialized"),
          });
          chirpInstance.init();
        } else {
          console.error("ChirpLink is still undefined.");
        }
      }, 500); // Wait for 500ms
    };

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    if (openIframe) {
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openIframe]);

  const getLoanData = async () => {
    const loanData = await getCustomerStatus();
    setPendingLoan(loanData?.loanStatus?.pending);

    if (loanData) {
      fetchBankVerificationDetails(loanData?.loanStatus?.pending?.loanId);
    }
  };

  const fetchBankVerificationDetails = async (loanId: string) => {
    const response = await getBankVerificationRequestCode(loanId);
    const data = response?.resource;
    setRequestCodeDetails({
      requestCode: data?.requestCode,
      iframeUrl: data?.iframeUrl,
    });
    if (data?.requestCode) {
      const failedAttemptCounts = await getBankVerificationFailedAttempts(
        loanId
      );
      setBankVerificationAttemptCount(failedAttemptCounts);
      if (failedAttemptCounts > 2) {
        setTransition("manualReview");
      } else {
        const res = await getBankVerificationRequestCodeStatus(loanId);
        if (res && res === "Verified") {
          getLokyataScore();
        } else {
          setLoaderMessage(
            "Please wait while the Bank Verification application loads"
          );
          setLoading(true);
          const token = await getChirpLinkToken(loanId);
          setLoading(false);
          setChirpLinkToken(token);
          setTransition("authorizationToken");
        }
      }
    }
  };

  useEffect(() => {
    if (transition === "authorizationToken") {
      setOpenIframe(true);
    }
    if (transition === "manualReview") {
      navigate("/Portal/upload");
    } else if (transition === "lokyataScore") {
      validateLokyataDetails();
    } else if (transition === "fundingChecklist") {
      navigate("/Portal/fundingChecklist");
    } else if (transition === "loan") {
      navigate("/Portal");
    } else if (transition === "bankverificationv2") {
      navigate("/Portal/bankverificationv2");
    }
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transition, lokyataScore]);

  const bankVerificationCallback = useCallback(
    (result: any) => {
      if (pendingLoan) {
        setTimeout(() => {
          getLokyataScore();
        }, 5000);
      } else {
        setTransition("manualReview");
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pendingLoan]
  );

  const getLokyataScore = async () => {
    setLoaderMessage(
      "We're crunching the numbers. Please do not refresh or close your browser. It can take 2-3 minutes to go through bank details to confirm your income deposits. If you have not been redirected after this time, please call us at 801-477-1222 to help you with the next steps."
    );
    setLoading(true);
    const data = await getLokyataScoreData(pendingLoan?.loanId);
    setLoading(false);
    setLokyataScore(data);
    setTransition("lokyataScore");
  };

  const validateLokyataDetails = () => {
    if (lokyataScore) {
      if (lokyataScore.loanDenied === true) {
        setTransition("portal");
      } else if (lokyataScore.manualReview === true) {
        setTransition("manualReview");
      } else if (lokyataScore.scoreAvailable === true) {
        setLoaderMessage(
          "The next step of the approval process is to verify how you would like to receive funds and make payments."
        );
        setLoading(true);
        setTransition("fundingChecklist");
      } else {
        setTransition("loan");
      }
    }
  };

  const handleIframeClose = () => {
    setOpenIframe(false);
    if (bankVerificationAttemptCount > 2) {
      setLoaderMessage(
        "We were unable to connect your bank account to verify your income after three credential errors. Please upload documents and our support team will review them manually."
      );
      setLoading(true);
      setTransition("manualReview");
    } else {
      setTransition("bankverificationv2");
    }
  };

  return (
    <>
      {requestCodeDetails && (
        <>
          <Typography
            variant="body1"
            paragraph
            sx={{
              color: "#838588",
              fontSize: "14px",
              fontWeight: "400",
              mt: 4,
            }}
          >
            <strong>
              Please verify your bank to receive your direct deposit
            </strong>{" "}
            and enter the same credentials you use when logging in to your
            online financial institution. <br />
            <br /> You will have 3 attempts to verify your bank account, please
            ensure your credentials are up to date.
          </Typography>
        </>
      )}

      {requestCodeDetails?.iframeUrl && chirpLinkToken && (
        <div id="chirpLinkWidget">
          <ChirpLink
            token={chirpLinkToken}
            mode={"test"}
            onLoad={function (data: any): void {
              throw new Error("Function not implemented.");
            }}
            onSuccess={bankVerificationCallback}
            onClose={handleIframeClose}
            onBankSelect={fetch}
            onAttempt={function (data: any): void {
              throw new Error("Function not implemented.");
            }}
            onError={function (data: any): void {
              throw new Error("Function not implemented.");
            }}
          />
        </div>
      )}
      {requestCodeDetails?.requestCode && (
        <>
          <Typography
            variant="body2"
            paragraph
            sx={{
              color: "#838588",
              fontSize: "14px",
              fontWeight: "400",
              mt: 8,
            }}
          >
            This system operates using 256‐bit encryption. Username and
            passwords are never displayed, viewed, or stored. <br />
            <br /> We use this system because it’s the safest & most convenient
            way for our customers to verify their banking information. This
            information is a necessary step in approving your loan request.
          </Typography>
        </>
      )}
      <BackDrop loading={loading} message={loaderMessage ?? ""} />
    </>
  );
};

export default BankVerificationProcess;
