import {
  Container,
  Box,
  Typography,
  Grid,
  Button,
  Divider,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {
  getPendingLoanApplicationStep,
  getPendingLoanDetails,
} from "../../services/loanPortalService";
import { formatDateToDDMMMYYYY } from "../../utils/dateUtils";

type Props = {
  customerLoanStatus: any;
};

const PendingLoan = (props: Props) => {
  const { customerLoanStatus } = props;
  const [loanData, setLoanData] = useState<any | null>(null);
  const [pendingLoanApplicationStep, setPendingLoanApplicationStep] = useState<
    any | null
  >(null);
  const navigate = useNavigate();

  const fetchPendingLoan = async () => {
    try {
      const response = await getPendingLoanDetails();
      setLoanData(response);
      if (response) {
        const res = await getPendingLoanApplicationStep(response.id);
        if (res) {
          setPendingLoanApplicationStep(res);
        }
      }
    } catch (error) {
      console.error("Error fetching pending loan data:", error);
    }
  };

  useEffect(() => {
    fetchPendingLoan();
  }, []);

  const handleContinuePendingLoanClick = () => {
    const stepName = pendingLoanApplicationStep?.stepName;
    switch (stepName) {
      case "SignLoanAgreement":
        navigate("/Portal/sign");
        break;
      case "BankVerification":
        navigate("/Portal/bankVerificationProcess");
        break;
      case "FundingChecklist":
        navigate("/Portal/fundingChecklist");
        break;
      case "Completed":
        navigate("/Portal/loan");
        break;
      default:
        navigate("/Portal/upload");
    }
  };

  const getApprovalStatus = () => {
    let approvalStatus = customerLoanStatus?.pending?.approvalStatus;
    if (pendingLoanApplicationStep) {
      switch (pendingLoanApplicationStep.stepName) {
        case "SignLoanAgreement":
          approvalStatus = "Pending Final Signature";
          break;
        case "BankVerification":
          approvalStatus = "Pending Income Verification";
          break;
        case "FundingChecklist":
          approvalStatus = "Pending Payment Information";
          break;
        default:
          break;
      }
    }
    return approvalStatus;
  };

  const approvalStatus = getApprovalStatus();

  return (
    <Container maxWidth="md" sx={{ marginTop: "2rem" }}>
      <Box sx={{ textAlign: "left", marginBottom: "2rem" }}>
        <Typography variant="h5" sx={{ color: "#00db8f", fontWeight: "500" }}>
          Application Status: {approvalStatus}
        </Typography>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Box sx={{ padding: "2rem" }}>
            {customerLoanStatus?.pending?.amount && (
              <>
                <Typography
                  variant="body2"
                  sx={{
                    color: "#838588",
                    marginBottom: "1rem",
                    fontWeight: "bold",
                  }}
                >
                  Requested Loan Amount
                </Typography>
                <Typography variant="h6" sx={{ color: "#555658" }}>
                  {`$${parseFloat(customerLoanStatus?.pending?.amount).toFixed(
                    2
                  )}`}
                </Typography>
              </>
            )}

            {pendingLoanApplicationStep?.requestedLoanAmount > 0 && (
              <>
                <Typography
                  variant="body2"
                  sx={{
                    color: "#838588",
                    marginBottom: "1rem",
                    fontWeight: "bold",
                  }}
                >
                  Requested Loan Amount
                </Typography>
                <Typography variant="h6" sx={{ color: "#555658" }}>
                  {`$${pendingLoanApplicationStep.requestedLoanAmount}`}
                </Typography>
              </>
            )}

            {pendingLoanApplicationStep?.approvedLoanAmount > 0 && (
              <>
                <Typography
                  variant="body2"
                  sx={{
                    color: "#838588",
                    marginBottom: "1rem",
                    fontWeight: "bold",
                  }}
                >
                  Max Approval Amount:
                </Typography>
                <Typography variant="h6" sx={{ color: "#555658" }}>
                  {`$${pendingLoanApplicationStep.approvedLoanAmount(2)}`}
                </Typography>
              </>
            )}
            {loanData?.appliedDate && (
              <Typography
                variant="body2"
                sx={{
                  color: "#838588",
                  marginBottom: "2rem",
                  fontWeight: "bold",
                  mt: 1,
                }}
              >
                Application Date :{" "}
                {formatDateToDDMMMYYYY(loanData?.appliedDate)}
              </Typography>
            )}
            {loanData?.id && (
              <Typography
                variant="body2"
                sx={{
                  color: "#838588",
                  marginBottom: "6rem",
                  fontWeight: "bold",
                }}
              >
                Loan #: {loanData?.id}
              </Typography>
            )}
          </Box>
        </Grid>
        <Divider
          orientation="vertical"
          flexItem
          sx={{ marginTop: "2rem", marginBottom: "2rem" }}
        />
        <Grid item xs={12} sm={5}>
          <Box sx={{ padding: "2rem" }}>
            {pendingLoanApplicationStep?.stepName === "BankVerification" && (
              <>
                <Typography
                  variant="body2"
                  sx={{
                    color: "#838588",
                    marginBottom: "1rem",
                    fontWeight: "400",
                    fontSize: "16px",
                  }}
                >
                  The next step of your approval process is to verify your
                  income. Click the button below to proceed to the next step of
                  your application.
                </Typography>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#004d40",
                    color: "#fff",
                    "&:hover": {
                      backgroundColor: "#004d40",
                    },
                  }}
                  onClick={handleContinuePendingLoanClick}
                >
                  Verify Income <ChevronRightIcon />
                </Button>
              </>
            )}
            {pendingLoanApplicationStep?.stepName === "FundingChecklist" && (
              <>
                <Typography
                  variant="body2"
                  sx={{
                    color: "#838588",
                    marginBottom: "1rem",
                    fontWeight: "400",
                    fontSize: "16px",
                  }}
                >
                  The next step of the approval process is to verify how you
                  would like to receive funds and make payments.
                </Typography>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#004d40",
                    color: "#fff",
                    "&:hover": {
                      backgroundColor: "#004d40",
                    },
                  }}
                  onClick={handleContinuePendingLoanClick}
                >
                  Verify Payment Information <ChevronRightIcon />
                </Button>
              </>
            )}
            {pendingLoanApplicationStep?.stepName === "SignLoanAgreement" && (
              <>
                <Typography
                  variant="body2"
                  sx={{
                    color: "#838588",
                    marginBottom: "1rem",
                    fontWeight: "400",
                    fontSize: "16px",
                  }}
                >
                  The last step of the approval process is to e-sign your loan
                  agreement. Click the button below to go to the loan agreement
                  screen.
                </Typography>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#004d40",
                    color: "#fff",
                    "&:hover": {
                      backgroundColor: "#004d40",
                    },
                  }}
                  onClick={handleContinuePendingLoanClick}
                >
                  Sign Loan Agreement <ChevronRightIcon />
                </Button>
              </>
            )}
            {pendingLoanApplicationStep?.stepName === "Completed" &&
              approvalStatus === "Pending Final Approval" && (
                <>
                  <Typography
                    variant="body2"
                    sx={{
                      color: "#838588",
                      marginBottom: "1rem",
                      fontWeight: "400",
                      fontSize: "16px",
                    }}
                  >
                    Congratulations, your agreement has been signed and is
                    pending final approval! Our team will review your account
                    and once approved you will receive a final confirmation
                    text.
                    <br />
                    <br /> If you have any questions, reach out to us at
                    801-477-1222 or email us at support@liftcredit.com.
                  </Typography>
                </>
              )}

            {(approvalStatus === "Pending" ||
              approvalStatus === "Pending Final Approval") && (
              <>
                <Typography
                  variant="body2"
                  sx={{
                    color: "#838588",
                    marginBottom: "1rem",
                    fontWeight: "400",
                    fontSize: "16px",
                  }}
                >
                  Your application is being reviewed and is pending final
                  approval. A loan processor will be reaching out shortly.{" "}
                  <br />
                  <br /> If you need to re-upload or upload additional documents
                  for your file please use the Upload Documents button below.
                </Typography>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#004d40",
                    color: "#fff",
                    "&:hover": {
                      backgroundColor: "#004d40",
                    },
                  }}
                  onClick={handleContinuePendingLoanClick}
                >
                  Upload Documents <ChevronRightIcon />
                </Button>
              </>
            )}
            {pendingLoanApplicationStep?.stepName === "Review" &&
              (approvalStatus === "Pending" ||
                approvalStatus === "Pending Final Approval") && (
                <>
                  <Typography
                    variant="body2"
                    sx={{
                      color: "#838588",
                      marginBottom: "1rem",
                      fontWeight: "400",
                      fontSize: "16px",
                    }}
                  >
                    Your application is being reviewed and is pending final
                    approval. A loan processor will be reaching out shortly.
                    <br />
                    <br /> If you need to re-upload or upload additional
                    documents for your file please use the Upload Documents
                    button below.
                  </Typography>
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: "#004d40",
                      color: "#fff",
                      "&:hover": {
                        backgroundColor: "#004d40",
                      },
                    }}
                    onClick={handleContinuePendingLoanClick}
                  >
                    Upload Documents <ChevronRightIcon />
                  </Button>
                </>
              )}
            {pendingLoanApplicationStep && approvalStatus === "Approved" && (
              <>
                <Typography
                  variant="body2"
                  sx={{
                    color: "#838588",
                    marginBottom: "1rem",
                    fontWeight: "400",
                    fontSize: "16px",
                  }}
                >
                  Your loan has been approved and is ready to be signed. Please
                  review the loan agreement terms and sign to complete the
                  application by clicking the button below.
                </Typography>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#004d40",
                    color: "#fff",
                    "&:hover": {
                      backgroundColor: "#004d40",
                    },
                  }}
                  onClick={handleContinuePendingLoanClick}
                >
                  Sign Loan Agreement <ChevronRightIcon />
                </Button>
              </>
            )}
            {pendingLoanApplicationStep &&
              pendingLoanApplicationStep?.dateTimeApplicationEnd &&
              pendingLoanApplicationStep.stepName !== "Completed" && (
                <>
                  <Typography
                    variant="body2"
                    sx={{
                      color: "#838588",
                      marginBottom: "1rem",
                      fontWeight: "400",
                      fontSize: "16px",
                      mt: 1
                    }}
                  >
                    Application is valid until : &nbsp;{" "}
                    {formatDateToDDMMMYYYY(
                      pendingLoanApplicationStep.dateTimeApplicationEnd
                    )}
                  </Typography>
                </>
              )}
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default PendingLoan;
