import React, { useEffect, useState } from "react";
import { Modal, Paper, Box, Button, Typography, Backdrop, Container } from "@mui/material";
import { FundingChecklistStateType } from "./FundingChecklist";
import { saveBankAccount, saveBankCard } from "../../services/bankService";
import {
  updateAutoExtensionPaymentType,
  updatePendingLoan,
  updatePreferredPaymentType,
} from "../../services/loanPortalService";
import PaymentDetails from "./PaymentDetails";
import { BankAccountType, BankCardType } from "../../types/genericType";
import { validateBankAccount, validateBankCard } from "../../utils/utils";
import RadioButtons from "./RadioButtons";
import ModalButtons from "./ModalButtons";
import ModalActionButtons from "./ModalActionButtons";
import Loader from "../../components/loader/Loader";
import ErrorComp from "../../components/errorComp/ErrorComp";

type Props = {
  open: boolean;
  handleClose: () => void;
  data: FundingChecklistStateType;
  setData: (data: FundingChecklistStateType) => void;
  fundingCheck: string;
};

const initialError = {
  bankAccount: {
    bankName: "",
    bankAccountNumber: "",
    bankAccountType: "",
    bankRoutingNumber: "",
  },
  bankCard: {
    cardNumber: "",
    expiration: "",
    securityCode: "",
    nameOnCard: "",
    billingAddress: {
      addressLine1: "",
      city: "",
      state: "",
      zip: "",
    },
  },
};

const FundingModal = (props: Props) => {
  const { open, handleClose, data, setData, fundingCheck } = props;
  const { bankCards, bankAccounts, isEditable } = data;
  const { loanFundingMethod, defaultPaymentMethod } = data?.fundingChecklist;
  const [loanFundingData, setLoanFundingData] = useState<any>(null);
  const [defaultFundingData, setDefaultFundingData] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [loaderMessage, setLoaderMessage] = useState<string>("");
  const [error, setError] = useState<string>("");

  const initialState = {
    debitCard: false,
    bankAccount: false,
    hideButtons: false,
    selectedOrCreatedBankAccount: null,
    selectedOrCreatedBankCard: null,
    noDebitCardOption: false,
    noBankAccountOption: false,
    showExistingBankDetails: false,
    showBankCardDetails: false,
    errors: initialError,
  };

  const [state, setState] = useState<{
    debitCard: boolean;
    bankAccount: boolean;
    hideButtons: boolean;
    selectedOrCreatedBankAccount: BankAccountType | null;
    selectedOrCreatedBankCard: BankCardType | null;
    noDebitCardOption: boolean;
    noBankAccountOption: boolean;
    showExistingBankDetails: boolean;
    showBankCardDetails: boolean;
    errors: any;
  }>(initialState);

  useEffect(() => {
    if (isEditable && loanFundingData && defaultFundingData) {
      setState((prevState) => ({
        ...prevState,
        showExistingBankDetails: false,
        showBankCardDetails: true,
        hideButtons: true,
        bankAccount:
          fundingCheck === "loanFundingMethod"
            ? loanFundingData?.type === "bankAccount"
            : defaultFundingData?.type === "bankAccount",
        debitCard:
          fundingCheck === "loanFundingMethod"
            ? loanFundingData?.type === "bankCard"
            : defaultFundingData?.type === "bankCard",
      }));
    }
  }, [loanFundingData, defaultFundingData, isEditable, fundingCheck]);

  useEffect(() => {
    return () => {};
  }, []);

  useEffect(() => {
    if (loanFundingMethod?.bankAccountId > 0) {
      const bankAccount = bankAccounts.find((obj) => {
        return obj.id === loanFundingMethod.bankAccountId;
      });
      setLoanFundingData({ type: "bankAccount", data: bankAccount });
    } else if (loanFundingMethod?.bankCardId > 0) {
      const bankCard = bankCards.find((obj) => {
        return obj.id === loanFundingMethod.bankCardId;
      });
      setLoanFundingData({ type: "bankCard", data: bankCard });
    }
    if (defaultPaymentMethod?.bankAccountId > 0) {
      const bankAccount = bankAccounts.find((obj) => {
        return obj.id === defaultPaymentMethod.bankAccountId;
      });
      setDefaultFundingData({ type: "bankAccount", data: bankAccount });
    } else if (defaultPaymentMethod?.bankCardId > 0) {
      const bankCard = bankCards.find((obj) => {
        return obj.id === defaultPaymentMethod.bankCardId;
      });
      setDefaultFundingData({ type: "bankCard", data: bankCard });
    }
  }, [loanFundingMethod, defaultPaymentMethod, bankAccounts, bankCards]);

  const handleShowBankAccount = () => {
    setState({
      ...state,
      debitCard: false,
      bankAccount: true,
      hideButtons: true,
    });
  };

  const handleShowDebitCard = () => {
    setState({
      ...state,
      debitCard: true,
      bankAccount: false,
      hideButtons: true,
    });
  };

  const handleClickBack = () => {
    setState(initialState);
  };

  const updateSelectedBankAccount = (bankAccount: BankAccountType) => {
    setState((prevState) => ({
      ...prevState,
      selectedOrCreatedBankAccount: {
        ...bankAccount,
        isPrimaryBankAccount: true,
      },
    }));
  };

  const updateSelectedBankCard = (bankCard: BankCardType) => {
    setState((prevState) => ({
      ...prevState,
      selectedOrCreatedBankCard: { ...bankCard, isPrimaryBankCard: true },
    }));
  };

  const updatePendingLoanData = async (
    isLoanFunding: boolean,
    loanData: any
  ) => {
    try {
      setLoading(true);
      setLoaderMessage(
        isLoanFunding
          ? "Updating the Origination Date."
          : "Updating the Payment Due Date."
      );
      await updatePendingLoan(loanData);
      setLoading(false);
      setLoaderMessage("");
    } catch (error) {
      setError("Failed to update loan funding method. Please try again.");
      setLoading(false);
      setLoaderMessage("");
    }
  };

  const savePrefferedPaymentType = async (paymentType: string) => {
    try {
      setLoading(true);
      setLoaderMessage("Updating the Preferrd Payment Type.");
      await updatePreferredPaymentType({
        loanId: data.pendingLoan.id,
        paymentType: paymentType,
      });
      setLoading(false);
      setLoaderMessage("");
    } catch (error) {
      setError("Failed to save preferred payment type. Please try again.");
      setLoading(false);
      setLoaderMessage("");
    }
  };

  const saveAutoExtensionPaymentType = async (paymentType: string) => {
    try {
      setLoading(true);
      setLoaderMessage("Updating the Auto Extension Payment Type.");
      await updateAutoExtensionPaymentType({
        loanId: data.pendingLoan.id,
        paymentType: paymentType,
      });
      setLoading(false);
      setLoaderMessage("");
    } catch (error) {
      setError("Failed to save auto extension payment type. Please try again.");
      setLoading(false);
      setLoaderMessage("");
    }
  };

  const updatePendingLoanMethod = async (type: string) => {
    const isLoanFunding = fundingCheck === "loanFundingMethod";
    const isAchPayment = type === "Ach";

    const bankOrDebitCardDetails = isAchPayment
      ? { bankAccountId: state?.selectedOrCreatedBankAccount?.id }
      : { bankCardId: state?.selectedOrCreatedBankCard?.id };

    const loanData = {
      loanId: data.pendingLoan.id,
      fundingCheck: isLoanFunding
        ? "LoanFundingMethod"
        : "DefaultPaymentMethod",
      bankOrDebitCardDetails,
      ...(isLoanFunding
        ? { updateOriginationDate: true, paymentType: type }
        : {
            dueDate: data.dueDate,
            isMoveDueDateToNextBusinessDay: data.dueDateCheck,
          }),
    };
    if (!isLoanFunding) {
      await savePrefferedPaymentType(isAchPayment ? "Ach" : "DebitCard");
    }
    await updatePendingLoanData(isLoanFunding, loanData);
    if (!isLoanFunding) {
      await saveAutoExtensionPaymentType(isAchPayment ? "Ach" : "DebitCard");
    }
  };

  const updateBankCard = async (selectedOrCreatedBankCard: BankCardType) => {
    try {
      setLoading(true);
      setLoaderMessage("");
      await saveBankCard(selectedOrCreatedBankCard);
      setLoading(false);
    } catch (error) {
      setError("Failed to save bank card details. Please try again.");
      setLoading(false);
    }
  };

  const saveBankCardDetails = () => {
    const { selectedOrCreatedBankCard, errors } = state || {};
    if (!selectedOrCreatedBankCard) return;
    const isNewCard =
      selectedOrCreatedBankCard.id === 0 ||
      selectedOrCreatedBankCard.id === null;
    const saveBankAccountData = async () => {
      await updateBankCard(selectedOrCreatedBankCard);
      await updatePendingLoanMethod("CardDeposit");
      handleModalClose();
    };

    if (isNewCard) {
      const [isValid, newErrors] = validateBankCard(selectedOrCreatedBankCard);
      if (!isValid) {
        setState((prevState) => ({
          ...prevState,
          errors: { ...errors, bankCard: newErrors },
        }));
        return;
      }
    }
    saveBankAccountData();
  };

  const updateBankAccount = async (
    selectedOrCreatedBankAccount: BankAccountType
  ) => {
    try {
      setLoading(true);
      setLoaderMessage("");
      await saveBankAccount(selectedOrCreatedBankAccount);
      setLoading(false);
    } catch (error) {
      setError("Failed to save bank account details. Please try again.");
      setLoading(false);
    }
  };

  const saveBankAccountDetails = () => {
    const { selectedOrCreatedBankAccount, errors } = state;
    if (!selectedOrCreatedBankAccount) return;
    const isNewAccount =
      selectedOrCreatedBankAccount.id === 0 ||
      selectedOrCreatedBankAccount.id === null;
    const saveBankAccountData = async () => {
      await updateBankAccount(selectedOrCreatedBankAccount);
      await updatePendingLoanMethod("Ach");
      handleModalClose();
    };

    if (isNewAccount) {
      const [isValid, newErrors] = validateBankAccount(
        selectedOrCreatedBankAccount
      );
      if (!isValid) {
        setState((prevState) => ({
          ...prevState,
          errors: { ...errors, bankAccount: newErrors },
        }));
        return;
      }
    }
    saveBankAccountData();
  };

  const handleClickSave = () => {
    if (state.debitCard) {
      saveBankCardDetails();
    }
    if (state.bankAccount) {
      saveBankAccountDetails();
    }
  };

  const handleModalClose = () => {
    setState(initialState);
    handleClose();
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Paper
        sx={{
          maxWidth: "900px",
          maxHeight: "85vh", // Set max height for scrolling within modal
          overflowY: "auto",
          margin: "auto",
          marginTop: "8%",
          marginBottom: "8%",
          pl: 2,
          pb: 4,
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            sx={{
              fontSize: "16px",
              fontWeight: "600",
              paddingLeft: "8px",
              marginBottom: "5px",
              color: "#D3D3D3",
            }}
            onClick={handleModalClose}
          >
            X
          </Button>
        </Box>
        {error && (
          <Container>
            <ErrorComp message={error} onClose={() => setError("")} />
          </Container>
        )}
        {((fundingCheck === "defaultFundingMethod" &&
          (state.showBankCardDetails || state.showExistingBankDetails)) ||
          fundingCheck === "loanFundingMethod") && (
          <Typography
            sx={{
              fontSize: "28px",
              fontWeight: "500",
              textAlign: "center",
              color: "#838588",
            }}
            gutterBottom
          >
            {fundingCheck === "loanFundingMethod"
              ? "Verify how you will receive funds"
              : "Verify your default funding method"}
          </Typography>
        )}
        {!isEditable && !state.hideButtons && (
          <ModalButtons
            fundingCheck={fundingCheck}
            handleShowDebitCard={handleShowDebitCard}
            handleShowBankAccount={handleShowBankAccount}
            state={state}
            setState={setState}
            loanFundingData={loanFundingData}
          />
        )}
        {isEditable ? (
          <RadioButtons
            state={state}
            setState={setState}
            loanFundingData={loanFundingData}
            defaultFundingData={defaultFundingData}
            fundingCheck={fundingCheck}
          />
        ) : null}
        {(state.hideButtons ||
          state.showBankCardDetails ||
          state.showExistingBankDetails) && (
          <PaymentDetails
            bankAccount={state.bankAccount}
            debitCard={state.debitCard}
            bankAccounts={
              state.showExistingBankDetails ? [bankAccounts[0]] : bankAccounts
            }
            bankCards={
              state.showExistingBankDetails ? [bankCards[0]] : bankCards
            }
            updateSelectedBankAccount={updateSelectedBankAccount}
            updateSelectedBankCard={updateSelectedBankCard}
            errors={state.errors}
            showExistingBankDetails={state.showExistingBankDetails}
            fundingChecklistState={data}
            fundingCheck={fundingCheck}
          />
        )}
        {state.hideButtons && (
          <ModalActionButtons
            fundingCheck={fundingCheck}
            handleClickBack={handleClickBack}
            handleClickSave={handleClickSave}
            state={data}
            setState={setData}
          />
        )}
        <Backdrop
          open={loading}
          sx={{
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            zIndex: 9999,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "800px",
            }}
          >
            <Loader message={loaderMessage} />
          </Box>
        </Backdrop>
      </Paper>
    </Modal>
  );
};

export default FundingModal;
