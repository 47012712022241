import React, { useState } from "react";
import { Box, Container, Typography } from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import IconBtn from "../../components/IconBtn/IconBtn";
import UploadDocs from "./UploadDocs";
import { Doc, LoanDocuments } from "../../types/genericType";
import { uploadDocuments } from "../../services/loanPortalService";

const LoanDocs = () => {
  const [loanDocuments, setLoanDocuments] = useState<LoanDocuments>({
    proofOfIncome1: {} as Doc,
    proofOfIncome2: {} as Doc,
    photoId: {} as Doc,
    useExistingPhotoId: false,
  });

  const handleDocumentChange = (
    change: any,
    controlId: keyof LoanDocuments
  ) => {
    setLoanDocuments((prev) => ({
      ...prev,
      [controlId]: change,
    }));
  };

  const handleSubmitDocuments = async () => {
    const documents: Doc[] = [];
    if (loanDocuments.proofOfIncome1?.dataUrl) {
      documents.push(loanDocuments.proofOfIncome1);
    }
    if (loanDocuments.proofOfIncome2?.dataUrl) {
      documents.push(loanDocuments.proofOfIncome2);
    }
    if (loanDocuments.photoId?.dataUrl) {
      documents.push(loanDocuments.photoId);
    }

    await uploadDocuments(documents);
  };

  return (
    <Container>
      <Box sx={{ padding: "2rem", textAlign: "center" }}>
        <Typography
          variant="h5"
          component="h5"
          fontWeight="500"
          fontSize={26}
          color="#00db8f"
        >
          We've Received Your Application!*
        </Typography>
        <Typography
          variant="h5"
          component="h5"
          sx={{ color: "#838588", fontSize: "12px", fontWeight: "400", mt: 2 }}
        >
          Your application is submitted, but we can't approve your loan until
          you upload docs.
        </Typography>
      </Box>
      <UploadDocs
        loanDocuments={loanDocuments}
        requireSecondProofOfIncome={false}
        allowUseExistingPhotoId={loanDocuments.useExistingPhotoId ?? false}
        onChange={handleDocumentChange}
      />
      <Box display="flex" justifyContent="center" mt={4}>
        <IconBtn
          icon={<LockIcon sx={{ fontSize: "18px", color: "#00db8f" }} />}
          handleBtnClick={() => {
            handleSubmitDocuments();
          }}
          title="CONTINUE"
          type="contained"
        />
      </Box>
    </Container>
  );
};

export default LoanDocs;
