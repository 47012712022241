import React, { useEffect, useState } from "react";
import { Container, Box, Button } from "@mui/material";
import { DropdownOption } from "../../components/common/Dropdown";
import IconButton from "../../components/IconBtn/IconBtn";
import LockIcon from "@mui/icons-material/Lock";
import { LoanApplication } from "../../services/leadsService";
import {
  getAdvertisingMethods,
  getEligibilityRequirements,
  getLendingPartner,
  getLendingPartnerStates,
  getLoanOptions,
  submitLoanApplication,
} from "../../services/loanPortalService";
import { createLoanApplicationRequestBody } from "../../utils/utils";
import { useNavigate } from "react-router-dom";
import BackDrop from "../../components/common/BackDrop";
import LoanAppStep5 from "./LoanAppStep5";
import RedirectToPortal from "./RedirectToPortal";

interface StepFiveProps {
  prevStep: () => void;
}

const StepFive = ({ prevStep }: StepFiveProps) => {
  const [advertisingMethods, setAdvertisingMethods] = useState<
    DropdownOption[]
  >([]);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [captchaToken, setCaptchaToken] = useState<string | null>(null);
  const [email, setEmail] = useState("");
  const [lendingPartnerStates, setLendingPartnerStates] = useState<string[]>(
    []
  );
  const [eligibilityRequirements, setEligibilityRequirements] = useState<any>(
    {}
  );
  const [applicationSubmitted, setApplicationSubmitted] = useState(false);
  const [loaderMessage, setLoaderMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [transition, setTransition] = useState("");
  const [redirectUrl, setRedirectUrl] = useState("");
  const [lendingPartnerResponse, setLendingPartnerResponse] = useState<any>();
  const [loanApplicationData, setLoanApplicationData] =
    useState<LoanApplication>({
      verification: "",
      firstName: "",
      lastName: "",
      primaryAddress: {
        addressLine1: "",
        city: "",
        state: "",
        zip: "",
        timeAtAddress: "",
      },
      email: "",
      monthlyIncome: "",
      dateOfBirth: "",
      phoneNumber: "",
      consentToMarketing: false,
      bankVerificationRequestResult: "",
      socialSecurityNumber: "",
      advertisingMethod: "",
      advertisingMethodText: null,
      licenseNumber: "",
      licenseState: "",
      timeAtAddress: "",
      optInToMarketingMessage: false,
      optInToReceiveTextMessage: false,
      incomeSources: [],
      bankAccounts: [],
      bankCard: {
        nameOnCard: "",
        cardNumber: "",
        expiration: "",
        billingAddressSameAsPrimary: false,
        billingAddress: {
          addressLine1: "",
          city: "",
          state: "",
          zip: "",
          timeAtAddress: "",
        },
      },
      loanRequest: {
        loanTypeId: null,
        loanType: "",
        loanAmount: "",
        state: "",
        zip: "",
        loanRequestType: "",
        repaymentMethod: "",
        promoCode: "",
      },
      clarityStatus: "",
      status: "",
      loanApplicationId: "",
      redirectUrl: "",
      bankruptcyClear: false,
    });

  const navigate = useNavigate();

  const validate = () => {
    const newErrors: { [key: string]: string } = {};
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  useEffect(() => {
    if (captchaToken) {
      setErrors({
        ...errors,
        captcha: "",
      });
      setLoanApplicationData({
        ...loanApplicationData,
        verification: captchaToken,
      });
    }
  }, [captchaToken, errors, loanApplicationData]);

  const fetchAdvertisingMethods = async (state: string) => {
    try {
      const response = await getAdvertisingMethods(state);
      const options =
        response?.resources?.length > 0 &&
        response?.resources?.map((method: { value: any; name: any }) => {
          return {
            value: method.value,
            label: method.name,
          };
        });
      setAdvertisingMethods(options || []);
      handleFieldChange("advertisingMethod", options?.[0]?.value);
    } catch (error) {
      console.error("Error fetching advertising methods:", error);
    }
  };

  const fetchLoanOptions = async (data: any) => {
    const response = await getLoanOptions(
      data.loanAmount,
      data.primaryAddress.state,
      data.incomeSources?.[0]?.payDay?.payFrequency,
      "New"
    );
    const loanOption = response?.resources?.[0];
    setLoanApplicationData({
      ...loanApplicationData,
      ...data,
      loanRequest: {
        loanTypeId: loanOption?.loanTypeId,
        loanType: "PersonalLoan",
        loanAmount: loanOption?.loanAmount,
        state: data.primaryAddress?.state,
        zip: data.primaryAddress.zip,
        loanRequestType: "New",
        repaymentMethod: "Ach",
        promoCode: loanApplicationData.loanRequest?.promoCode,
      },
    });
  };

  const handleFieldChange = (field: string, value: any) => {
    if (field === "promoCode") {
      setLoanApplicationData({
        ...loanApplicationData,
        loanRequest: {
          ...loanApplicationData.loanRequest,
          [field]: value,
        },
      });
      sessionStorage.setItem(
        "LoanApplication",
        JSON.stringify({
          ...loanApplicationData,
          loanRequest: {
            ...loanApplicationData.loanRequest,
            [field]: value,
          },
        })
      );
    } else {
      setLoanApplicationData({
        ...loanApplicationData,
        [field]: value,
      });
      sessionStorage.setItem(
        "LoanApplication",
        JSON.stringify({ ...loanApplicationData, [field]: value })
      );
    }
  };

  const fetchLendingPartnerStates = async () => {
    const response = await getLendingPartnerStates();
    setLendingPartnerStates(response || []);
  };

  const fetchEligibilityRequirements = async () => {
    const response = await getEligibilityRequirements();
    setEligibilityRequirements(response || {});
  };

  useEffect(() => {
    fetchLendingPartnerStates();
    fetchEligibilityRequirements();
    const storedData = sessionStorage.getItem("LoanApplication");
    if (storedData) {
      try {
        const parsedData = JSON.parse(storedData);
        fetchAdvertisingMethods(parsedData?.primaryAddress?.state);
        fetchLoanOptions(parsedData);
        setEmail(parsedData.email);
        setLoanApplicationData({ ...loanApplicationData, ...parsedData });
      } catch (error) {
        console.error("Error parsing storedData from sessionStorage:", error);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validateLoanApplicationFields = () => {
    if (
      lendingPartnerStates?.includes(loanApplicationData?.primaryAddress?.state)
    ) {
      return "Lift Credit is not licensed to lend in the state you selected.";
    }

    if (eligibilityRequirements) {
      const minIncome = eligibilityRequirements.minimumMonthlyIncome;
      const monthlyIncome = parseFloat(loanApplicationData?.monthlyIncome);
      if (monthlyIncome < minIncome) {
        return `Sorry, your income doesn't quite meet our requirements. The minimum income requirement for a loan is $${minIncome} net per month. Please contact our team at 801-477-1222 for more information.`;
      } else if (applicationSubmitted && loanApplicationData.status === "R") {
        if (
          loanApplicationData.clarityStatus === "Deny" ||
          loanApplicationData.clarityStatus === ""
        ) {
          return "We are unable to offer you a loan right now due to consumer disclosure reports. Please contact Clarity Services for a copy of your report. You may also contact our team at 801-477-1222 for more information.";
        } else if (loanApplicationData.bankruptcyClear === false) {
          return "Sorry, due to your bankruptcy history we are not able to offer you a loan. Please contact our team at 801-477-1222 for more information.";
        }
      }
    }

    return "";
  };

  const getLendingPartnerMethod = async (validationResult: string) => {
    setLoaderMessage(
      `${validationResult} Unfortunately your loan application didn't meet our minimum requirements. We are connecting you with a lender in our partner network.`
    );
    setLoading(true);
    try {
      const response = await getLendingPartner(
        createLoanApplicationRequestBody(loanApplicationData)
      );
      if (response.id.includes("error")) {
        navigate("/sorry/no-lending-partner");
      } else {
        setLendingPartnerResponse(response);
      }
    } catch (error) {
      setLoaderMessage(
        "An error occurred while connecting to a lending partner. Please try again later."
      );
    } finally {
      setLoading(false);
    }

    return;
  };

  const submitLoanApplicationMethod = async () => {
    setLoaderMessage(
      "Thank you for applying with us! Our system is going through your application. This process could take up to 2 minutes. If the screen doesn't update after that time, please try refreshing your browser. Thanks for waiting!"
    );
    setLoading(true);
    try {
      const response = await submitLoanApplication(
        createLoanApplicationRequestBody(loanApplicationData)
      );
      setApplicationSubmitted(true);
      const parts = response.id.split("|");
      setLoanApplicationData({
        ...loanApplicationData,
        loanApplicationId: parts[0],
        status: parts[1],
        redirectUrl: parts[2],
        bankruptcyClear: parts[3] === "true",
        clarityStatus: parts[4],
      });
    } catch (error) {
      setLoaderMessage(
        "An error occurred while submitting your application. Please try again later."
      );
      navigate("/sorry", {
        state: {
          error: {
            message: error,
            details: error,
          },
        },
      });
    } finally {
      setLoading(false);
    }
  };

  const handleNext = () => {
    const validationResult = validateLoanApplicationFields();
    setTransition("next");
    if (validationResult) {
      getLendingPartnerMethod(validationResult);
    } else {
      submitLoanApplicationMethod();
    }
  };

  const handleApply = () => {
    if (!captchaToken) {
      setErrors({
        ...errors,
        captcha: "Please verify you are not a robot.",
      });
    }
    if (validate()) {
      handleNext();
    }
  };

  useEffect(() => {
    if (transition === "next") {
      const result = validateLoanApplicationFields();
      if (result !== "") {
        getLendingPartnerMethod(result);
        setTimeout(() => {
          setTransition("lendingPartnerRedirect");
        }, 250);
      } else {
        if (applicationSubmitted) {
          if (
            loanApplicationData.status === "A" ||
            loanApplicationData.status === "U"
          ) {
            setLoaderMessage(
              "Successfully created your loan application. You will be redirecting to the portal"
            );
            setRedirectUrl(loanApplicationData.redirectUrl);
          } else if (loanApplicationData.status === "R") {
            getLendingPartnerMethod(result);
            setTimeout(() => {
              setTransition("lendingPartnerRedirect");
            }, 250);
          }
        }
      }
    }
    if (transition === "lendingPartnerRedirect") {
      if (lendingPartnerResponse != null) {
        if (
          lendingPartnerResponse.status !== "" &&
          lendingPartnerResponse.status === "sold"
        ) {
          setLoaderMessage(
            "Congrats! We were able to connect you with a lender in our partner network. You will be redirected momentarily to their site to finish their approval process."
          );
          setRedirectUrl(lendingPartnerResponse.redirectUrl);
        } else {
          navigate("sorry/no-lending-partner");
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transition]);

  return redirectUrl ? (
    <RedirectToPortal redirectUrl={redirectUrl} />
  ) : (
    <Container>
      <LoanAppStep5
        advertisingMethods={advertisingMethods}
        email={email}
        loanApplicationData={loanApplicationData}
        setCaptchaToken={setCaptchaToken}
        handleFieldChange={handleFieldChange}
        errors={errors}
      />
      <Box display="flex" justifyContent="center" mt={4}>
        <IconButton
          icon={<LockIcon sx={{ fontSize: "18px", color: "#00db8f" }} />}
          handleBtnClick={handleApply}
          title="APPLY NOW"
          type="contained"
        />
      </Box>
      <Box display="flex" justifyContent="center" mt={2}>
        <Button
          variant="text"
          onClick={prevStep}
          sx={{
            color: "#00db8f",
            textTransform: "none",
            fontSize: "16px",
            fontWeight: "600",
          }}
        >
          Go Back
        </Button>
      </Box>

      <BackDrop loading={loading} message={loaderMessage} />
    </Container>
  );
};

export default StepFive;
