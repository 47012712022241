import { Box, Container, Grid, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import AmountInputField from "../../components/common/AmountField";
import LockIcon from "@mui/icons-material/Lock";
import IconBtn from "../../components/IconBtn/IconBtn";
import { NewLoanApplication } from "../../types/genericType";

interface Step1Props {
  updateLoanApplication: (data: Partial<NewLoanApplication>) => void;
  handleNextStep: () => void;
  handlePreviousStep: () => void;
}

const Step1: React.FC<Step1Props> = ({
  updateLoanApplication,
  handleNextStep,
  handlePreviousStep,
}) => {
  const [amount, setAmount] = useState("");
  const [promoCode, setPromoCode] = useState("");
  const [errors, setErrors] = useState({
    amount: "",
  });

  const validateAmount = (value: string): string => {
    if (!value) return "Amount is required";
    const parsedValue = parseFloat(value);
    if (isNaN(parsedValue) || parsedValue < 100 || parsedValue > 2500) {
      return "Enter an amount between $100.00 and $2,500.00";
    }
    return "";
  };

  const handleAmountChange = (value: string) => {
    setAmount(value);
    const error = validateAmount(value);
    setErrors((prevErrors) => ({ ...prevErrors, amount: error }));
  };

  const handleContinue = () => {
    const amountError = validateAmount(amount);
    if (amountError) {
      setErrors((prevErrors) => ({ ...prevErrors, amount: amountError }));
      return;
    }
    updateLoanApplication({ loanAmount: amount });
    sessionStorage.setItem("isIncomeConfirmed", JSON.stringify(false));
    handleNextStep();
  };

  return (
    <Container>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography
            variant="h6"
            sx={{
              fontSize: "12px",
              fontWeight: "600",
              paddingLeft: "8px",
              marginBottom: "5px",
              color: "#838588",
            }}
          >
            Requested Loan Amount
          </Typography>
          <AmountInputField
            label=""
            amount={amount}
            setAmount={(value: string) => handleAmountChange(value)}
            required
            error={!!errors.amount}
            helperText={errors.amount}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="h6"
            sx={{
              fontSize: "14px",
              fontWeight: "600",
              paddingLeft: "8px",
              marginBottom: "5px",
              color: "#838588",
            }}
          >
            Promo Code (Optional)
          </Typography>
          <TextField
            variant="outlined"
            value={promoCode}
            onChange={(e) => setPromoCode(e.target.value)}
            fullWidth
            size="small"
            placeholder="Enter Promo Code (Optional)"
          />
        </Grid>
      </Grid>
      <Box display="flex" justifyContent="center" mt={4}>
        <IconBtn
          icon={<LockIcon sx={{ fontSize: "18px", color: "#00db8f" }} />}
          handleBtnClick={handleContinue}
          title="CONTINUE"
          type="contained"
        />
      </Box>
    </Container>
  );
};

export default Step1;
