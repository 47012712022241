import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getBankVerificationRequestCode } from "../../services/bankVerificationService";
import { Box, Typography } from "@mui/material";
import { getCustomerStatus } from "../../services/customerService";

const BankVerificationProcessSuccess = () => {
  const navigate = useNavigate();
  const { requestCode } = useParams();

  interface State {
    bankVerification: any;
    loans: any;
    usingDefaultPin?: boolean;
  }

  const [state, setState] = useState<State>({
    bankVerification: null,
    loans: null,
  });

  const fetchVerificationDetails = async () => {
    const loanData = await getCustomerStatus();
    const loanId =
      (loanData?.loanStatus?.pending &&
        loanData?.loanStatus?.pending?.loanId) ||
      loanData?.loanStatus?.current?.loanId;
    const response = await getBankVerificationRequestCode(loanId);
    const bankVerificationDetails = response?.resource;
    setState({ loans: loanData, bankVerification: bankVerificationDetails });
  };

  useEffect(() => {
    fetchVerificationDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const doInitialActions = async () => {
      if (requestCode === state?.bankVerification?.requestCode) {
        if (state.loans?.credentialsStatus?.usingDefaultPin === false) {
          navigate("/Portal/reviewing");
        } else if (state.usingDefaultPin === true) {
          navigate("/Portal/welcome");
        } else {
          navigate("/Portal");
        }
      } else {
        navigate("sorry/not-matching-request-code");
      }
    };

    doInitialActions();
  }, [navigate, requestCode, state]);

  return (
    <Box sx={{ padding: "2rem", textAlign: "center" }}>
      <Typography fontSize={28} fontWeight="500" color="#00db8f">
        Bank Verification Process Successfully!
      </Typography>
    </Box>
  );
};

export default BankVerificationProcessSuccess;
