import React from "react";
import {
  Grid,
  Typography,
  Box,
  TextField,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import Dropdown from "../../components/common/Dropdown";
import AddressForm from "../../components/addressForm/AddressForm";
import Constants from "../../constants/constant";
import { calculateNextPayDates, formatDateToMMDD } from "../../utils/dateUtils";
import { ClearIcon } from "@mui/x-date-pickers";
import { handlePhoneNumberChange, normalizeTimeRangeValue } from "../../utils/utils";
import { IncomeType } from "../../types/genericType";

interface IncomeSourceProps {
  incomeSource: IncomeType;
  index: number;
  errors: any;
  handleIncomeSourceChange: Function;
  handleAddressChange: (index: number, updatedAddress: any) => void;
  handleRemoveIncomeSource: (index: number) => void;
}

const IncomeSource = ({
  incomeSource,
  index,
  errors,
  handleIncomeSourceChange,
  handleAddressChange,
  handleRemoveIncomeSource,
}: IncomeSourceProps) => {
  

  const renderExtraFields = (payFrequency: string, index: number) => {
    switch (payFrequency) {
      case "weekly":
        return (
          <Grid item xs={6}>
            <Dropdown
              id="payDay"
              label="Payday"
              value={incomeSource?.payDay?.payDay?.toLocaleLowerCase()}
              options={Constants.dayOfWeekOptions.map((option) => ({
                value: option.value,
                label: option.name,
              }))}
              onChange={(val) => handleIncomeSourceChange(index, "payDay", val)}
              required={true}
              error={errors?.payDay?.payDay}
            />
          </Grid>
        );
      case "biWeekly":
        return (
          <>
            <Grid item xs={6}>
              <Dropdown
                id="payDay"
                label="Payday"
                value={incomeSource?.payDay?.payDay?.toLocaleLowerCase()}
                options={Constants.dayOfWeekOptions.map((option) => ({
                  value: option.value,
                  label: option.name,
                }))}
                onChange={(val) => {
                  handleIncomeSourceChange(index, "payDay", val);
                  handleIncomeSourceChange(index, "nextPayDate", "");
                }}
                required={true}
                error={errors?.payDay?.payDay}
              />
            </Grid>
            {incomeSource?.payDay?.payDay && (
              <Grid item xs={6}>
                <Typography
                  variant="h6"
                  sx={{
                    fontSize: "12px",
                    fontWeight: "600",
                    paddingLeft: "8px",
                    marginBottom: "5px",
                    color: "#838588",
                  }}
                >
                  Next Pay Date
                </Typography>
                <RadioGroup
                  row
                  value={incomeSource?.payDay?.nextPayDate}
                  onChange={(e) =>
                    handleIncomeSourceChange(
                      index,
                      "nextPayDate",
                      e.target.value
                    )
                  }
                  sx={{
                    display: "flex",
                    border: errors?.payDay.nextPayDate ? "1px solid red" : "",
                  }}
                >
                  {calculateNextPayDates(incomeSource?.payDay?.payDay).map(
                    (date, idx) => {
                      const formattedDate = formatDateToMMDD(new Date(date));
                      return (
                        <FormControlLabel
                          key={idx}
                          value={date}
                          control={
                            <Radio
                              sx={{
                                "&.MuiRadio-root": {
                                  display: "none",
                                },
                              }}
                            />
                          }
                          label={formattedDate}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            height: "40px",
                            padding: 1,
                            cursor: "pointer",
                            marginLeft: 1,
                            textAlign: "center",
                            color: "#333",
                            "&:hover": {
                              backgroundColor: "#e0e0e0",
                            },
                            border:
                              date === incomeSource?.payDay?.nextPayDate
                                ? "1px solid #00db8f"
                                : "",
                            backgroundColor:
                              date === incomeSource?.payDay?.nextPayDate
                                ? "#e0e0e0"
                                : "white",
                            borderRadius: "4px",
                          }}
                        />
                      );
                    }
                  )}
                </RadioGroup>
                {errors?.payDay.nextPayDate && (
                  <Typography color="error" variant="caption">
                    {errors?.payDay.nextPayDate}
                  </Typography>
                )}
              </Grid>
            )}
          </>
        );
      case "monthly":
        return (
          <>
            <Grid item xs={6}>
              <Typography
                variant="h6"
                sx={{
                  fontSize: "12px",
                  fontWeight: "600",
                  paddingLeft: "8px",
                  marginBottom: "5px",
                  color: "#838588",
                }}
              >
                I get paid on
              </Typography>
              <RadioGroup
                row
                value={incomeSource?.payDay?.monthlyPayDayType}
                onChange={(e) =>
                  handleIncomeSourceChange(
                    index,
                    "monthlyPayDayType",
                    e.target.value
                  )
                }
                sx={{
                  display: "flex",
                  border: errors?.payDay.monthlyPayDayType
                    ? "1px solid red"
                    : "",
                }}
              >
                <FormControlLabel
                  value={"Day"}
                  control={
                    <Radio
                      sx={{
                        "&.MuiRadio-root": {
                          display: "none",
                        },
                      }}
                    />
                  }
                  label="Specific Day"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "40px",
                    padding: 1,
                    cursor: "pointer",
                    marginLeft: 1,
                    textAlign: "center",
                    color: "#333",
                    "&:hover": {
                      backgroundColor: "#e0e0e0",
                    },
                    border:
                      incomeSource?.payDay?.monthlyPayDayType === "Day"
                        ? "1px solid #00db8f"
                        : "",
                    backgroundColor:
                      incomeSource?.payDay?.monthlyPayDayType === "Day"
                        ? "#e0e0e0"
                        : "white",
                    borderRadius: "4px",
                  }}
                />
                <FormControlLabel
                  value={"DayOfWeek"}
                  control={
                    <Radio
                      sx={{
                        "&.MuiRadio-root": {
                          display: "none",
                        },
                      }}
                    />
                  }
                  label="Day of Week"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "40px",
                    padding: 1,
                    cursor: "pointer",
                    marginLeft: 1,
                    textAlign: "center",
                    color: "#333",
                    "&:hover": {
                      backgroundColor: "#e0e0e0",
                    },
                    border:
                      incomeSource?.payDay?.monthlyPayDayType === "DayOfWeek"
                        ? "1px solid #00db8f"
                        : "",
                    backgroundColor:
                      incomeSource?.payDay?.monthlyPayDayType === "DayOfWeek"
                        ? "#e0e0e0"
                        : "white",
                    borderRadius: "4px",
                  }}
                />
              </RadioGroup>
              {errors?.payDay.monthlyPayDayType && (
                <Typography color="error" variant="caption">
                  {errors?.payDay.monthlyPayDayType}
                </Typography>
              )}
            </Grid>

            {incomeSource?.payDay?.monthlyPayDayType === "DayOfWeek" ? (
              <Grid item xs={6}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Dropdown
                      id="week"
                      label="Pay Day of Month"
                      value={incomeSource?.payDay?.firstPayWeek ?? ""}
                      options={Constants.weekOfMonthOptions.map((option) => ({
                        value: option.value,
                        label: option.name,
                      }))}
                      onChange={(val) =>
                        handleIncomeSourceChange(index, "firstPayWeek", val)
                      }
                      required={true}
                      error={errors?.payDay.firstPayWeek}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Dropdown
                      id="day"
                      label=""
                      value={
                        incomeSource?.payDay?.firstMonthlyPayDayOfWeek ?? ""
                      }
                      options={Constants.dayOfWeekOptions.map((option) => ({
                        value: option.value,
                        label: option.name,
                      }))}
                      onChange={(val) =>
                        handleIncomeSourceChange(
                          index,
                          "firstMonthlyPayDayOfWeek",
                          val
                        )
                      }
                      required={true}
                      error={errors?.payDay.firstMonthlyPayDayOfWeek}
                    />
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              <Grid item xs={6}>
                <Grid container spacing={2}>
                  <Grid item xs={9}>
                    <Dropdown
                      id="week"
                      label="Pay Day of Month"
                      value={incomeSource?.payDay?.firstMonthlyPayDay ?? ""}
                      options={Constants.dayOfMonthOptions.map((option) => ({
                        value: option.value,
                        label: option.name,
                      }))}
                      onChange={(val) =>
                        handleIncomeSourceChange(
                          index,
                          "firstMonthlyPayDay",
                          val
                        )
                      }
                      required={true}
                      error={errors?.payDay.firstMonthlyPayDay}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Typography
                      variant="h6"
                      sx={{
                        fontSize: "12px",
                        fontWeight: "500",
                        paddingLeft: "8px",
                        marginBottom: "5px",
                        marginTop: "30px",
                        color: "#838588",
                      }}
                    >
                      of month
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </>
        );
      case "twiceMonthly":
        return (
          <>
            <Grid item xs={6}>
              <Typography
                variant="h6"
                sx={{
                  fontSize: "12px",
                  fontWeight: "600",
                  paddingLeft: "8px",
                  marginBottom: "5px",
                  color: "#838588",
                }}
              >
                I get paid on
              </Typography>
              <RadioGroup
                row
                value={incomeSource?.payDay?.monthlyPayDayType}
                onChange={(e) =>
                  handleIncomeSourceChange(
                    index,
                    "monthlyPayDayType",
                    e.target.value
                  )
                }
                sx={{ display: "flex" }}
              >
                <FormControlLabel
                  value={"Day"}
                  control={
                    <Radio
                      sx={{
                        "&.MuiRadio-root": {
                          display: "none",
                        },
                      }}
                    />
                  }
                  label="Specific Day"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "40px",
                    padding: 1,
                    cursor: "pointer",
                    marginLeft: 1,
                    textAlign: "center",
                    color: "#333",
                    "&:hover": {
                      backgroundColor: "#e0e0e0",
                    },
                    border:
                      incomeSource?.payDay?.monthlyPayDayType === "Day"
                        ? "1px solid #00db8f"
                        : "",
                    backgroundColor:
                      incomeSource?.payDay?.monthlyPayDayType === "Day"
                        ? "#e0e0e0"
                        : "white",
                    borderRadius: "4px",
                  }}
                />
                <FormControlLabel
                  value={"DayOfWeek"}
                  control={
                    <Radio
                      sx={{
                        "&.MuiRadio-root": {
                          display: "none",
                        },
                      }}
                    />
                  }
                  label="Day of Week"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "40px",
                    padding: 1,
                    cursor: "pointer",
                    marginLeft: 1,
                    textAlign: "center",
                    color: "#333",
                    "&:hover": {
                      backgroundColor: "#e0e0e0",
                    },
                    border:
                      incomeSource?.payDay?.monthlyPayDayType === "DayOfWeek"
                        ? "1px solid #00db8f"
                        : "",
                    backgroundColor:
                      incomeSource?.payDay?.monthlyPayDayType === "DayOfWeek"
                        ? "#e0e0e0"
                        : "white",
                    borderRadius: "4px",
                  }}
                />
              </RadioGroup>
              {errors?.payDay?.monthlyPayDayType && (
                <Typography color="error" variant="caption">
                  {errors?.payDay?.monthlyPayDayType}
                </Typography>
              )}
            </Grid>
            {incomeSource?.payDay?.monthlyPayDayType === "DayOfWeek" ? (
              <>
                <Grid item xs={6}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Dropdown
                        id="week"
                        label="First Pay Day of Month"
                        value={incomeSource?.payDay?.firstPayWeek ?? ""}
                        options={Constants.weekOfMonthOptions.map((option) => ({
                          value: option.value,
                          label: option.name,
                        }))}
                        onChange={(val) =>
                          handleIncomeSourceChange(index, "firstPayWeek", val)
                        }
                        required={true}
                        error={errors?.payDay?.firstPayWeek}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Dropdown
                        id="day"
                        label=""
                        value={
                          incomeSource?.payDay?.firstMonthlyPayDayOfWeek ?? ""
                        }
                        options={Constants.dayOfWeekOptions.map((option) => ({
                          value: option.value,
                          label: option.name,
                        }))}
                        onChange={(val) =>
                          handleIncomeSourceChange(
                            index,
                            "firstMonthlyPayDayOfWeek",
                            val
                          )
                        }
                        required={true}
                        error={errors?.payDay?.firstMonthlyPayDayOfWeek}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6}></Grid>
                <Grid item xs={6}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Dropdown
                        id="week"
                        label="Second Pay Day of Month"
                        value={incomeSource?.payDay?.secondPayWeek ?? ""}
                        options={Constants.weekOfMonthOptions.map((option) => ({
                          value: option.value,
                          label: option.name,
                        }))}
                        onChange={(val) =>
                          handleIncomeSourceChange(index, "secondPayWeek", val)
                        }
                        required={true}
                        error={errors?.payDay?.secondPayWeek}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Dropdown
                        id="day"
                        label=""
                        value={
                          incomeSource?.payDay?.secondMonthlyPayDayOfWeek ?? ""
                        }
                        options={Constants.dayOfWeekOptions.map((option) => ({
                          value: option.value,
                          label: option.name,
                        }))}
                        onChange={(val) =>
                          handleIncomeSourceChange(
                            index,
                            "secondMonthlyPayDayOfWeek",
                            val
                          )
                        }
                        required={true}
                        error={errors?.payDay?.secondMonthlyPayDayOfWeek}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </>
            ) : (
              <Grid item xs={6}>
                <Typography
                  variant="h6"
                  sx={{
                    fontSize: "12px",
                    fontWeight: "600",
                    paddingLeft: "8px",
                    color: "#838588",
                  }}
                >
                  I get paid on
                </Typography>
                <Grid container sx={{ mt: -2 }}>
                  <Grid item xs={5}>
                    <Dropdown
                      id="week"
                      label=""
                      value={incomeSource?.payDay?.firstMonthlyPayDay ?? ""}
                      options={Constants.dayOfMonthOptions.map((option) => ({
                        value: option.value,
                        label: option.name,
                      }))}
                      onChange={(val) =>
                        handleIncomeSourceChange(
                          index,
                          "firstMonthlyPayDay",
                          val
                        )
                      }
                      required={true}
                      error={errors?.payDay?.firstMonthlyPayDay}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <Typography
                      variant="h6"
                      sx={{
                        fontSize: "12px",
                        fontWeight: "500",
                        paddingLeft: "18px",
                        marginBottom: "5px",
                        marginTop: "30px",
                        color: "#838588",
                      }}
                    >
                      and
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Dropdown
                      id="week"
                      label=""
                      value={incomeSource?.payDay?.secondMonthlyPayDay ?? ""}
                      options={Constants.dayOfMonthOptions.map((option) => ({
                        value: option.value,
                        label: option.name,
                      }))}
                      onChange={(val) =>
                        handleIncomeSourceChange(
                          index,
                          "secondMonthlyPayDay",
                          val
                        )
                      }
                      required={true}
                      error={errors?.payDay?.secondMonthlyPayDay}
                    />
                  </Grid>
                </Grid>
              </Grid>
            )}
          </>
        );
      default:
        return null;
    }
  };

  return (
    <React.Fragment key={index}>
      {index === 1 && (
        <Grid item xs={12}>
          <Box
            display="flex"
            justifyContent="start"
            alignItems="center"
            sx={{
              color: "#00db8f",
              fontWeight: 500,
              cursor: "pointer",
              "&:hover": {
                color: "#00594f",
              },
            }}
            onClick={() => handleRemoveIncomeSource(index)}
          >
            <ClearIcon fontSize="small" />
            <Typography
              sx={{
                fontFamily: "AvenirNext-DemiBold",
                marginLeft: "4px",
              }}
            >
              Remove Income Source
            </Typography>
          </Box>
        </Grid>
      )}
      <Grid item xs={12}>
        <Dropdown
          id="incomeType"
          label="Income Source"
          value={incomeSource.incomeType}
          options={Constants.incomeTypeOptions.map((option) => ({
            value: option.value,
            label: option.name,
          }))}
          onChange={(val) => handleIncomeSourceChange(index, "incomeType", val)}
          required={true}
          error={errors?.incomeType}
        />
      </Grid>
      {incomeSource.incomeType === "employment" && (
        <>
          <Grid item xs={12}>
            <Typography
              variant="h6"
              sx={{
                fontSize: "12px",
                fontWeight: "600",
                paddingLeft: "8px",
                marginBottom: "5px",
                color: "#838588",
              }}
            >
              Employer Name
            </Typography>
            <TextField
              variant="outlined"
              value={incomeSource.employer}
              onChange={(e) =>
                handleIncomeSourceChange(index, "employer", e.target.value)
              }
              fullWidth
              size="small"
              placeholder="Enter Employer Name"
              required
              error={!!errors?.employer}
              helperText={errors?.employer}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="h6"
              sx={{
                fontSize: "12px",
                fontWeight: "600",
                paddingLeft: "8px",
                color: "#838588",
              }}
            >
              Employer Address
            </Typography>
          </Grid>
          <AddressForm
            value={incomeSource.employerAddress}
            onChange={(updatedAddress: any) =>
              handleAddressChange(index, updatedAddress)
            }
            required
            errors={errors?.employerAddress}
          />
        </>
      )}
      {(incomeSource.incomeType === "employment" ||
        incomeSource.incomeType === "selfEmployed") && (
        <>
          <Grid item xs={6}>
            <Dropdown
              id="timeAtAddress"
              label="Time at Employer"
              value={normalizeTimeRangeValue(
                incomeSource.timeAtEmployment || ""
              )}
              options={Constants.timeRangeOptions.map((option) => ({
                value: option.value,
                label: option.name,
              }))}
              onChange={(value: string) =>
                handleIncomeSourceChange(index, "timeAtEmployment", value)
              }
              error={errors?.timeAtEmployment}
              required
            />
          </Grid>
          <Grid item xs={6}>
            <Typography
              variant="h6"
              sx={{
                fontSize: "12px",
                fontWeight: "600",
                paddingLeft: "8px",
                marginBottom: "5px",
                color: "#838588",
              }}
            >
              Employer Phone
            </Typography>
            <TextField
              id="workPhoneNumber"
              variant="outlined"
              value={incomeSource.workPhoneNumber || ""}
              onChange={(e) => handlePhoneNumberChange(e, (val) => handleIncomeSourceChange(index, "workPhoneNumber", val))}
              fullWidth
              size="small"
              placeholder="XXX-XXX-XXXX"
              required={false}
              error={!!errors?.workPhoneNumber}
              helperText={errors?.workPhoneNumber}
            />
          </Grid>
        </>
      )}
      {incomeSource.incomeType === "other" && (
        <Grid item xs={12}>
          <Typography
            variant="h6"
            sx={{
              fontSize: "12px",
              fontWeight: "600",
              paddingLeft: "8px",
              marginBottom: "5px",
              color: "#838588",
            }}
          >
            Description
          </Typography>
          <TextField
            variant="outlined"
            value={incomeSource.otherIncomeDescription}
            onChange={(e) =>
              handleIncomeSourceChange(
                index,
                "otherIncomeDescription",
                e.target.value
              )
            }
            fullWidth
            size="small"
            placeholder="Income Source Description"
            error={!!errors?.otherIncomeDescription}
          />
          {errors?.otherIncomeDescription && (
            <Typography color="error" variant="caption">
              {errors?.otherIncomeDescription}
            </Typography>
          )}
        </Grid>
      )}
      <Grid item xs={6}>
        <Dropdown
          id="payrollType"
          label="Payroll Information"
          value={incomeSource.payDay.payrollType}
          options={Constants.payrollTypeOptions.map((option) => ({
            value: option.value,
            label: option.name,
          }))}
          onChange={(val) =>
            handleIncomeSourceChange(index, "payrollType", val)
          }
          required={true}
          error={errors?.payDay.payrollType}
        />
      </Grid>
      <Grid item xs={6}>
        <Dropdown
          id="payFrequency"
          label="Pay Period"
          value={incomeSource.payDay?.payFrequency}
          options={Constants.payFrequencyOptions.map((option) => ({
            value: option.value,
            label: option.name,
          }))}
          onChange={(val) =>
            handleIncomeSourceChange(index, "payFrequency", val)
          }
          required={true}
          error={errors?.payDay.payFrequency}
        />
      </Grid>
      {renderExtraFields(incomeSource.payDay?.payFrequency, index)}
    </React.Fragment>
  );
};

export default IncomeSource;
