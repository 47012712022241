import { Box, Grid, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import LockIcon from "@mui/icons-material/Lock";
import IconButton from "../../components/IconBtn/IconBtn";
import { validateCustomerInfo } from "../../services/customerService";
import { useNavigate } from "react-router-dom";

const LoanSignature = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    ssn: "",
  });

  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    ssn: "",
  });

  const navigate = useNavigate();

  const handleFormChange = (key: string, value: string) => {
    setFormData({ ...formData, [key]: value });
  };

  const handleSubmit = async () => {
    let errorObj = {
      firstName: "",
      lastName: "",
      ssn: "",
    };

    if (!formData.firstName) {
      errorObj = { ...errorObj, firstName: "Required Field" };
    }

    if (!formData.lastName) {
      errorObj = { ...errorObj, lastName: "Required Field" };
    }

    if (!formData.ssn) {
      errorObj = { ...errorObj, ssn: "Required Field" };
    }

    setErrors(errorObj);

    if (Object.values(errorObj).some((err) => err)) {
      return;
    } else {
      const res = await validateCustomerInfo(formData);
      if (!res.allow) {
        setErrors({
          ...errorObj,
          firstName:
            "The information provided does not match our records. Please try again.",
        });
      } else {
        navigate("/Portal/complete");
      }
    }
  };

  const handleSSNChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.slice(0, 4); // Limit the SSN input to 4 characters
    handleFormChange("ssn", value);
  };

  return (
    <Box sx={{ mt: 2, p: 2, backgroundColor: "rgba(0, 219, 143, .3)" }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography
            variant="h6"
            sx={{
              fontSize: "14px",
              fontWeight: "600",
              paddingLeft: "8px",
              color: "#838588",
            }}
          >
            Name
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <TextField
            variant="outlined"
            value={formData.firstName}
            onChange={(e) => handleFormChange("firstName", e.target.value)}
            fullWidth
            size="small"
            placeholder="First Name"
            required
            error={!!errors?.firstName}
            helperText={errors?.firstName}
            sx={{
              borderRadius: "5px",
              "& .MuiInputBase-root": {
                backgroundColor: "white",
              },
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            variant="outlined"
            value={formData.lastName}
            onChange={(e) => handleFormChange("lastName", e.target.value)}
            fullWidth
            size="small"
            placeholder="Last Name"
            required
            error={!!errors?.lastName}
            helperText={errors?.lastName}
            sx={{
              borderRadius: "5px",
              "& .MuiInputBase-root": {
                backgroundColor: "white",
              },
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="h6"
            sx={{
              fontSize: "14px",
              fontWeight: "600",
              paddingLeft: "8px",
              color: "#838588",
            }}
          >
            Last 4 SSN
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            value={formData.ssn}
            type="number"
            onChange={handleSSNChange}
            fullWidth
            size="small"
            placeholder="XXXX"
            required
            error={!!errors?.ssn}
            helperText={errors?.ssn}
            sx={{
              borderRadius: "5px",
              "& .MuiInputBase-root": {
                backgroundColor: "white",
              },
            }}
          />
        </Grid>
      </Grid>
      <Box display="flex" justifyContent="center" mt={4}>
        <IconButton
          icon={<LockIcon sx={{ fontSize: "18px", color: "#00db8f" }} />}
          handleBtnClick={handleSubmit}
          title="SIGN AGREEMENT"
          type="contained"
        />
      </Box>
    </Box>
  );
};

export default LoanSignature;
