import React from "react";
import { BankCardType } from "../../types/genericType";
import { Box, Radio, Typography } from "@mui/material";
import { Star } from "@mui/icons-material";

type Props = {
  bankCard: BankCardType;
  selectedBankCardId: number | undefined;
  setSelectedBankCardId: (id: number) => void;
  hideRadio: boolean;
};

const PaymentBankCard = (props: Props) => {
  const { bankCard, selectedBankCardId, setSelectedBankCardId, hideRadio } =
    props;

  return (
    <Box
      sx={{
        display: "flex",
        padding: hideRadio ? "40px" : "20px",
        alignItems: "start",
        backgroundColor:
          selectedBankCardId === bankCard.id && !hideRadio
            ? "#f4f4f4"
            : "white",
      }}
    >
      {!hideRadio && (
        <Radio
          checked={selectedBankCardId === bankCard.id}
          sx={{
            padding: 0,
            color: "#00db8f",
            "&.Mui-checked": {
              color: "#00db8f",
            },
            mr: 2,
          }}
          onChange={() => setSelectedBankCardId(bankCard.id ?? 0)}
        />
      )}
      <Box
        sx={{
          display: "flex",
          gap: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "2px",
            width: "300px",
            justifyContent: "start",
          }}
        >
          <Typography
            variant="body2"
            sx={{
              color: "#838588",
              fontWeight: "500",
            }}
          >
            {bankCard?.nameOnCard}
          </Typography>
          <Typography
            variant="body2"
            sx={{
              color: "#838588",
              fontWeight: "500",
              fontStyle: "italic",
            }}
          >
            {bankCard.cardNumber}
          </Typography>
          <Typography
            variant="body2"
            sx={{
              color: "#838588",
              fontWeight: "500",
              fontStyle: "italic",
            }}
          >
            {bankCard.expiration}
          </Typography>
          {bankCard.isPrimaryBankCard && (
            <Typography
              variant="body2"
              sx={{
                color: "#838588",
                fontWeight: "500",
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
              }}
            >
              <Star sx={{ fontSize: "18px", color: "#838588", mr: 1 }} />
              Primary Debit Card
            </Typography>
          )}
        </Box>
        {bankCard?.billingAddress && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "2px",
              justifyContent: "start",
            }}
          >
            <Typography
              variant="body2"
              sx={{
                color: "#838588",
                fontWeight: "500",
              }}
            >
              Billing Address
            </Typography>
            <Typography
              variant="body2"
              sx={{
                color: "#838588",
                fontWeight: "500",
              }}
            >
              {bankCard?.billingAddress?.addressLine1}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                color: "#838588",
                fontWeight: "500",
              }}
            >
              {bankCard?.billingAddress?.city},{" "}
              {bankCard?.billingAddress?.state} {bankCard?.billingAddress?.zip}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default PaymentBankCard;
