import { useLocation, useNavigate, useParams } from "react-router-dom";
import SorryActiveMilitaryPage from "./SorryActiveMilitaryPage";
import SorryAddressPage from "./SorryAddressPage";
import SorryAgeTooLowPage from "./SorryAgeTooLowPage";
import SorryAutoDenialPage from "./SorryAutoDenialPage";
import SorryDenialLendingPartnerPage from "./SorryDenialLendingPartnerPage";
import SorryExistingUserPage from "./SorryExistingUserPage";
import SorryGenericPage from "./SorryGenericPage";
import SorryIncomeTooLowPage from "./SorryIncomeTooLowPage";
import SorryJobHistoryPage from "./SorryJobHistoryPage";
import SorryPayPeriodPage from "./SorryPayPeriodPage";
import Button from "@mui/material/Button";
import { Box } from "@mui/material";
import SorryNotEligible from "./SorryNotEligible";

const SorryPage = () => {
  const { reason } = useParams();
  const location = useLocation();
  const error = location.state?.error;
  const navigate = useNavigate();
  const storedData = sessionStorage.getItem("LoanApplication");
  const parsedData = storedData ? JSON.parse(storedData) : null;

  const renderPage = () => {
    const backButton = (
      <Box display="flex" justifyContent="center" mt={2}>
        <Button
          variant="text"
          onClick={() => navigate(-1)}
          sx={{
            color: "#00db8f",
            textTransform: "none",
            fontSize: "16px",
            fontWeight: "600",
          }}
        >
          Go Back
        </Button>
      </Box>
    );

    switch (reason) {
      case "address":
        return (
          <>
            <SorryAddressPage />
            {backButton}
          </>
        );
      case "age":
        return (
          <>
            <SorryAgeTooLowPage />
            {backButton}
          </>
        );
      case "income":
        return (
          <>
            <SorryIncomeTooLowPage />
            {backButton}
          </>
        );
      case "pay-period":
        return (
          <>
            <SorryPayPeriodPage />
            {backButton}
          </>
        );
      case "job-history":
        return (
          <>
            <SorryJobHistoryPage />
            {backButton}
          </>
        );
      case "existing-user":
        return (
          <>
            <SorryExistingUserPage checkedProperty="email address" />
            {backButton}
          </>
        );
      case "existing-ssn":
        return (
          <>
            <SorryExistingUserPage checkedProperty="social security number" />
            {backButton}
          </>
        );
      case "military":
        return (
          <>
            <SorryActiveMilitaryPage />
            {backButton}
          </>
        );
      case "not-qualified":
        return (
          <>
            <SorryAutoDenialPage />
            {backButton}
          </>
        );
      case "no-lending-partner":
        return (
          <>
            <SorryDenialLendingPartnerPage
              firstName={parsedData && parsedData?.firstName}
            />
          </>
        );
      case "not-eligible": 
        return (
          <>
            <SorryNotEligible error={error} />
            {backButton}
          </>
        );
      default:
        return (
          <>
            <SorryGenericPage error={error} />
            {backButton}
          </>
        );
    }
  };

  return renderPage();
};

export default SorryPage;
