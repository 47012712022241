import React, { useEffect, useState } from "react";
import { Grid, TextField, Typography } from "@mui/material";
import Constants from "../../constants/constant";
import Dropdown from "../../components/common/Dropdown";
import { getCityStateByZip } from "../../services/addressService";
import { Address } from "../../interfaces";
import { normalizeTimeRangeValue } from "../../utils/utils";

interface AddressFormProps {
  value?: Address;
  onChange: (change: { id: string; value: string }) => void;
  required?: boolean;
  detailAddress?: boolean;
  errors?: Address;
  timeAtAddressLabel?: string;
  addressRequired?: boolean;
  addressLabel?: string;
}

const AddressForm: React.FC<AddressFormProps> = ({
  value = {},
  onChange,
  required = false,
  detailAddress = false,
  errors = {},
  timeAtAddressLabel = "Time at Address",
  addressRequired = false,
  addressLabel = "Street Address",
}) => {
  const [address, setAddress] = useState<Address>(value);

  useEffect(() => {
    if (address.zip && address.zip.length === 5) {
      getCityStateByZip(address.zip)
        .then((res) => {
          if (res) {
            const newCity = res.city;
            const newState = res.stateShort;

            setAddress((prevAddress) => ({
              ...prevAddress,
              city: newCity,
              state: newState,
            }));

            onChange({ id: "city", value: newCity });
            onChange({ id: "state", value: newState });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address.zip]);

  const handleChange = (id: string, newValue: string) => {
    const updatedAddress = { ...address, [id]: newValue };
    setAddress(updatedAddress);
    onChange({ id, value: newValue });
  };

  useEffect(() => {
    setAddress(value);
  }, [value]);

  return (
    <>
      {addressRequired && (
        <Grid item xs={12}>
          <Typography
            variant="h6"
            sx={{
              fontSize: "12px",
              fontWeight: "600",
              paddingLeft: "8px",
              marginBottom: "5px",
              color: "#838588",
            }}
          >
            {addressLabel}
          </Typography>
          <TextField
            id="addressLine1"
            variant="outlined"
            value={address.addressLine1 || ""}
            onChange={(e) => handleChange("addressLine1", e.target.value)}
            fullWidth
            size="small"
            placeholder="Street Address"
            required={addressRequired}
            error={addressRequired && !!errors.addressLine1}
            helperText={addressRequired && errors.addressLine1}
          />
        </Grid>
      )}
      <Grid item xs={4}>
        <Typography
          variant="h6"
          sx={{
            fontSize: "12px",
            fontWeight: "600",
            paddingLeft: "8px",
            marginBottom: "5px",
            color: "#838588",
          }}
        >
          Zip
        </Typography>
        <TextField
          id="zip"
          variant="outlined"
          value={address.zip || ""}
          onChange={(e) => handleChange("zip", e.target.value)}
          fullWidth
          size="small"
          placeholder="Zip"
          required={required}
          error={!!errors.zip}
          helperText={errors.zip}
        />
      </Grid>
      <Grid item xs={4}>
        <Typography
          variant="h6"
          sx={{
            fontSize: "12px",
            fontWeight: "600",
            paddingLeft: "8px",
            marginBottom: "5px",
            color: "#838588",
          }}
        >
          City
        </Typography>
        <TextField
          id="city"
          variant="outlined"
          value={address.city || ""}
          onChange={(e) => handleChange("city", e.target.value)}
          fullWidth
          size="small"
          placeholder="City"
          required={required}
          error={!!errors.city}
          helperText={errors.city}
        />
      </Grid>
      <Grid item xs={4}>
        <Dropdown
          id="state"
          label="State"
          value={address.state || ""}
          options={Constants.stateOptions.map((option) => ({
            value: option.value,
            label: option.name,
          }))}
          onChange={(value: string) => handleChange("state", value)}
          error={errors.state}
          required={required}
        />
      </Grid>
      {detailAddress && (
        <>
          <Grid item xs={6}>
            <Dropdown
              id="timeAtAddress"
              label={timeAtAddressLabel}
              value={normalizeTimeRangeValue(address?.timeAtAddress || "")}
              options={Constants.timeRangeOptions.map((option) => ({
                value: option.value,
                label: option.name,
              }))}
              onChange={(value: string) => handleChange("timeAtAddress", value)}
              error={errors.timeAtAddress}
              required={detailAddress && required}
            />
          </Grid>
        </>
      )}
    </>
  );
};

export default AddressForm;
