import React, { useEffect, useState } from "react";

interface ChirpLinkProps {
  token: string;
  mode: string;
  onLoad: (data: any) => void;
  onSuccess: (data: any) => void;
  onClose: (data: any) => void;
  onBankSelect: (data: any) => void;
  onAttempt: (data: any) => void;
  onError: (data: any) => void;
}

const ChirpLink: React.FC<ChirpLinkProps> = ({
  token,
  mode,
  onLoad,
  onSuccess,
  onClose,
  onBankSelect,
  onAttempt,
  onError,
}) => {
  const [iframeLoaded, setIframeLoaded] = useState(false);
  const [iframeContainerId, setIframeContainerId] = useState("");
  const [closePage, setClosePage] = useState("CUSTOMER_CONSENT");

  useEffect(() => {
    fetch(`https://chirp.digital/api/getRequestCodeForVerification/${token}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (!response.ok) {
          return Promise.reject("Token not found or invalid");
        } else {
          return response.json();
        }
      })
      .then((data) => {
        if (data.success) {
          loadVerification(data.requestCode);
        } else {
          onError({ success: false, errorMessage: data.errorDescription });
        }
      })
      .catch((error) => {
        onError({ success: false, errorMessage: error });
      });

    if (mode === "ADAPTABLE" || mode === "FULL_WIDTH") {
      setIframeContainerId(
        mode === "ADAPTABLE" ? "adaptableContainer" : "fullWidthContainer"
      );
    } else {
      setIframeContainerId("iframeContainer");
    }

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, mode, onError]);

  const handleMessage = (event: { origin: string; data: any }) => {
    if (event.origin === "https://widget.decisionlogic.com") {
      const data = event.data;
      if (data === "windowClose") {
        closeVerification();
      } else if (data.onLoad) {
        onLoad(data);
      } else if (data.onSuccess) {
        onSuccess(data);
      } else if (data.onError) {
        onError(data);
      } else if (data.bankSelected) {
        onBankSelect(data);
      } else if (data.attempted) {
        onAttempt(data);
      } else if (data.currentPage) {
        setClosePage(data.closePage);
      }
    }
  };

  const loadVerification = (requestCode: any) => {
    const iframe = document.createElement("iframe");
    iframe.src = `https://chirp.digital/api/widget?requestCode=${requestCode}&token=${token}&chirpAPIVerificationWidgetv2=true`;
    iframe.id = "myIframe";
    iframe.allowFullscreen = true;
    iframe.frameBorder = "0";
    iframe.style.width = "100%";
    iframe.style.height = "95%";

    const loading = document.createElement("div");
    loading.id = "load";
    loading.innerHTML = '<p id="loadText">Loading...</p>';

    const iframeContainer = document.getElementById(iframeContainerId);
    if (iframeContainer && iframeContainer !== null) {
      iframeContainer.innerHTML = "";
    }
    iframeContainer?.appendChild(loading);
    iframeContainer?.appendChild(iframe);

    setIframeLoaded(true);

    // Event listener for postMessage from iframe
    window.addEventListener("message", handleMessage);
  };

  const closeVerification = () => {
    onClose({ pageClosed: true, closedPage: closePage });
    const iframeContainer = document.getElementById(iframeContainerId);
    if (iframeContainer && iframeContainer !== null) {
      iframeContainer.innerHTML = "";
    }
  };

  return (
    <div
      id="chirpVerification"
      style={{
        display: iframeLoaded ? "block" : "none",
        zIndex: 1000,
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "25%",
        boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.1)",
        borderRadius: "16px",
        height: "85%",
        maxHeight: "650px",
      }}
    >
      <div id={iframeContainerId} />
      {iframeLoaded && (
        <button
          id="closeBtn"
          onClick={closeVerification}
          style={{ position: "absolute", bottom: 0 }}
        >
          Close
        </button>
      )}
    </div>
  );
};

export default ChirpLink;
