import { Box, Radio, Typography } from "@mui/material";
import React, { useEffect } from "react";

type Props = {
  state: any;
  setState: any;
  loanFundingData: any;
  defaultFundingData: any;
  fundingCheck: string;
};

const RadioButtons = (props: Props) => {
  const { state, setState, loanFundingData, defaultFundingData, fundingCheck } =
    props;

  const initialType =
    fundingCheck === "loanFundingMethod"
      ? loanFundingData.type
      : defaultFundingData.type;

  const initialData =
    fundingCheck === "loanFundingMethod"
      ? loanFundingData.data
      : defaultFundingData.data;

  const getAccountSuffix = (type: string) => {
    if (type === "bankAccount") {
      return initialData.bankAccountNumber?.slice(-8) || "";
    } else {
      return initialData.cardNumber?.slice(-8) || "";
    }
  };

  useEffect(() => {
    if (initialType === "bankAccount") {
      setState((prevState: any) => ({
        ...prevState,
        bankAccount: true,
        debitCard: false,
      }));
    } else {
      setState((prevState: any) => ({
        ...prevState,
        bankAccount: false,
        debitCard: true,
      }));
    }
  }, [initialType, setState]);

  const renderRadioButtons = () => {
    const firstOptionIsBank = initialType === "bankAccount";
    const suffix = getAccountSuffix(initialType);

    const options = firstOptionIsBank
      ? ["bankAccount", "debitCard"]
      : ["debitCard", "bankAccount"];

    return options.map((option, index) => (
      <Box
        key={option}
        sx={{
          display: "flex",
          alignItems: "start",
          padding: "10px",
        }}
      >
        <Radio
          checked={state[option]}
          sx={{
            padding: 0,
            color: "#00db8f",
            "&.Mui-checked": {
              color: "#00db8f",
            },
            mr: 2,
          }}
          onChange={() =>
            setState((prevState: any) => ({
              ...prevState,
              bankAccount: option === "bankAccount",
              debitCard: option === "debitCard",
            }))
          }
        />
        <Typography
          variant="body2"
          sx={{
            color: "#838588",
            fontWeight: "500",
          }}
        >
          {option === "bankAccount"
            ? `Bank Account ${index === 0 ? `${suffix}` : ""}`
            : `Debit Card ${index === 0 ? `${suffix}` : ""}`}
        </Typography>
      </Box>
    ));
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "start",
        alignItems: "start",
        paddingLeft: "30px",
      }}
    >
      {renderRadioButtons()}
    </Box>
  );
};

export default RadioButtons;
