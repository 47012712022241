import { Box, Button, Typography } from "@mui/material";
import React from "react";

type Props = {
  fundingCheck: string;
  handleShowDebitCard: () => void;
  handleShowBankAccount: () => void;
  state: any;
  setState: any;
  loanFundingData: any;
};

const ModalButtons = (props: Props) => {
  const {
    fundingCheck,
    handleShowDebitCard,
    handleShowBankAccount,
    state,
    setState,
    loanFundingData,
  } = props;

  const generateLabel = () => {
    if (loanFundingData?.type === "bankAccount") {
      return `Would you also like your payments to pull from your Bank Account ending ${loanFundingData.data.bankAccountNumber.slice(
        -4
      )}`;
    } else if (loanFundingData?.type === "bankCard") {
      return `Would you also like your payments to pull from your Debit Card ending ${loanFundingData?.data?.cardNumber.slice(
        -4
      )}`;
    }
  };

  return fundingCheck === "loanFundingMethod" ? (
    <Box
      textAlign="center"
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-evenly",
        marginTop: "2rem",
        alignItems: "center",
      }}
    >
      <Button
        variant="contained"
        sx={{
          color: "white",
          backgroundColor: "#00db8f",
          textTransform: "none",
          width: "245px",
          mt: 2,
          ":hover": {
            backgroundColor: "#00db8f",
          },
        }}
        onClick={handleShowBankAccount}
      >
        Bank Account (ACH)
      </Button>
      <Button
        variant="contained"
        sx={{
          color: "white",
          backgroundColor: "#00db8f",
          textTransform: "none",
          width: "245px",
          mt: 3,
          mb: 4,
          ":hover": {
            backgroundColor: "#00db8f",
          },
          ":disabled": {
            backgroundColor: "rgba(0, 219, 143, .65)",
            color: "white",
          },
        }}
        onClick={handleShowDebitCard}
      >
        Debit Card (Visa or Mastercard)
      </Button>
    </Box>
  ) : (
    <>
      <Typography
        sx={{
          fontSize: "16px",
          fontWeight: "500",
          textAlign: "center",
          color: "#838588",
        }}
      >
        {generateLabel()}
      </Typography>
      <Box
        textAlign="center"
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          marginTop: "2rem",
          alignItems: "center",
        }}
      >
        <Button
          variant="contained"
          sx={{
            color: "white",
            backgroundColor: "#00db8f",
            textTransform: "none",
            ":hover": {
              backgroundColor: "#00db8f",
            },
          }}
          onClick={() => {
            setState({
              ...state,
              showExistingBankDetails: true,
              showBankCardDetails: false,
              hideButtons: true,
              bankAccount: loanFundingData?.type === "bankAccount",
              debitCard: loanFundingData?.type === "bankCard",
            });
          }}
        >
          Yes
        </Button>
        <Button
          variant="contained"
          sx={{
            color: "white",
            backgroundColor: "#00db8f",
            textTransform: "none",
            ml: 2,
            ":hover": {
              backgroundColor: "#00db8f",
            },
            ":disabled": {
              backgroundColor: "rgba(0, 219, 143, .65)",
              color: "white",
            },
          }}
          onClick={() => {
            setState({
              ...state,
              showExistingBankDetails: false,
              showBankCardDetails: true,
              hideButtons: true,
              bankAccount: loanFundingData?.type === "bankCard",
              debitCard: loanFundingData?.type === "bankAccount",
            });
          }}
        >
          No, I will enter it manually
        </Button>
      </Box>
    </>
  );
};

export default ModalButtons;
