import {
  Box,
  Checkbox,
  Typography,
  Button,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { formatDateMinusOneDay } from "../../utils/dateUtils";
import { FundingChecklistStateType } from "./FundingChecklist";

type Props = {
  fundingCheck: string;
  handleClickBack: () => void;
  handleClickSave: () => void;
  state: FundingChecklistStateType;
  setState: (data: FundingChecklistStateType) => void;
};

const ModalActionButtons = (props: Props) => {
  const { fundingCheck, handleClickBack, handleClickSave, state, setState } =
    props;
  const [disclaimerCheck, setDisclaimerCheck] = useState(
    state?.isEditable ? true : false
  );
  const [isMoveDueDateToNextBusinessDay, setIsMoveDueDateToNextBusinessDay] =
    useState(state?.isEditable ? state?.dueDateCheck : false);
  const [dueDate, setDueDate] = useState(state?.isEditable ? state?.dueDate : "");
  const [errors, setErrors] = useState({
    disclaimerCheck: "",
    dueDate: "",
  });

  const handleSaveClick = () => {
    const dueDateError =
      fundingCheck === "defaultFundingMethod" && !dueDate
        ? "Required Field"
        : "";

    if (!disclaimerCheck || dueDateError) {
      setErrors({
        ...errors,
        disclaimerCheck: !disclaimerCheck ? "Required Field" : "",
        dueDate: dueDateError,
      });
    } else {
      setErrors({
        disclaimerCheck: "",
        dueDate: "",
      });
      handleClickSave();
    }
  };

  const handleCheckBoxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDisclaimerCheck(e.target.checked);
    if (!e.target.checked) {
      setErrors({ ...errors, disclaimerCheck: "Required Field" });
    } else {
      setErrors({
        disclaimerCheck: "",
        dueDate: "",
      });
    }
  };

  const getDateOptions = () => {
    if (isMoveDueDateToNextBusinessDay) {
      return state?.nextBusinessDueDates;
    } else {
      return state?.dueDates;
    }
  };

  useEffect(() => {
    setState({
      ...state,
      dueDate: dueDate,
      dueDateCheck: isMoveDueDateToNextBusinessDay,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dueDate, isMoveDueDateToNextBusinessDay]);

  return (
    <>
      {fundingCheck === "defaultFundingMethod" && (
        <>
          <Box
            sx={{
              display: "flex",
              padding: "20px",
              alignItems: "start",
              justifyContent: "start",
            }}
          >
            <Checkbox
              id="isMoveDueDateToNextBusinessDay"
              checked={isMoveDueDateToNextBusinessDay}
              onChange={(e) =>
                setIsMoveDueDateToNextBusinessDay(e.target.checked)
              }
              sx={{
                padding: 0,
                color: "#838588",
                "&.Mui-checked": {
                  color: "#838588",
                },
              }}
            />
            <Typography
              variant="body2"
              style={{
                color: "#838588",
                fontWeight: "500",
                fontSize: "12px",
                marginLeft: "16px",
              }}
            >
              Payments pull early in the morning. Would you like to move your
              due dates to the business day after your pay dates?
            </Typography>
          </Box>
          <Typography
            variant="body2"
            style={{
              color: "#838588",
              fontWeight: "500",
              fontSize: "12px",
              marginLeft: "24px",
            }}
          >
            Please select your first payment date below :<br />
            (If your pay frequency looks incorrect, please call us at
            801-477-1222 or click the Chat button in the bottom right to contact
            us)
          </Typography>
          <Grid item xs={12}>
            <RadioGroup
              value={dueDate}
              onChange={(e) => setDueDate(e.target.value)}
              sx={{
                display: "flex",
                alignItems: "start",
                justifyContent: "center",
                mt: 2,
                ml: 4,
                mr: 4,
                padding: "10px",
                border: errors.dueDate ? "1px solid red" : "none",
              }}
            >
              {getDateOptions().map((date: any) => (
                <FormControlLabel
                  value={date.value}
                  sx={{
                    display: "flex",
                    alignItems: "flex-start",
                  }}
                  control={
                    <Radio
                      sx={{
                        color: "#00db8f",
                        padding: "0px 9px !important",
                        "&.Mui-checked": {
                          color: "#00db8f",
                          borderRadius: "50%",
                        },
                      }}
                    />
                  }
                  label={
                    <Typography
                      variant="body2"
                      sx={{
                        fontSize: "12px",
                        color: "#838588",
                        marginBottom: "20px",
                        paddingTop: "3px",
                      }}
                    >
                      {formatDateMinusOneDay(date.value)}
                    </Typography>
                  }
                />
              ))}
            </RadioGroup>
            {errors.dueDate && (
              <Typography
                variant="caption"
                color="error"
                sx={{ marginLeft: "45px" }}
              >
                {errors.dueDate}
              </Typography>
            )}
          </Grid>
        </>
      )}
      <Box
        sx={{
          display: "flex",
          padding: "20px",
          alignItems: "start",
          justifyContent: "start",
        }}
      >
        <Checkbox
          id="disclaimerCheck"
          checked={disclaimerCheck}
          onChange={handleCheckBoxChange}
          required
          sx={{
            padding: 0,
            color: errors.disclaimerCheck ? "red" : "#838588",
            "&.Mui-checked": {
              color: "#838588",
            },
          }}
        />
        <Typography
          variant="body2"
          style={{
            color: "#838588",
            fontWeight: "500",
            fontSize: "12px",
            marginLeft: "16px",
          }}
        >
          You verify that the method you select for funding the loan is correct,
          it belongs to you, is active, and able to receive a transfer of funds.
          Lift Credit cannot be responsible for information entered incorrectly,
          and you are responsible for subsequent payments.
        </Typography>
      </Box>
      {errors.disclaimerCheck && (
        <Typography variant="caption" color="error" sx={{ marginLeft: "45px" }}>
          {errors.disclaimerCheck}
        </Typography>
      )}
      <Box sx={{ textAlign: "center", mt: 2, mb: 6 }}>
        {fundingCheck === "loanFundingMethod" && !state.isEditable && (
          <Button
            variant="text"
            size="small"
            sx={{
              color: "#838588",
              textTransform: "none",
              marginRight: 2,
            }}
            onClick={handleClickBack}
          >
            Back
          </Button>
        )}
        <Button
          variant="contained"
          size="small"
          sx={{
            color: "white",
            backgroundColor: "#00db8f",
            textTransform: "none",
            ":hover": {
              backgroundColor: "#00db8f",
            },
          }}
          onClick={handleSaveClick}
        >
          Save
        </Button>
      </Box>
    </>
  );
};

export default ModalActionButtons;
