import { Container, Box, Typography, Grid, TextField } from "@mui/material";
import React, { useEffect } from "react";

import LockIcon from "@mui/icons-material/Lock";
import IconButton from "../../components/IconBtn/IconBtn";
import { createPassword } from "../../services/authService";
import { useNavigate } from "react-router-dom";

const Welcome = () => {
  const [password, setPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [passwordChanged, setPasswordChanged] = React.useState(false);
  const [errors, setErrors] = React.useState({
    password: "",
    confirmPassword: "",
  });
  const navigate = useNavigate();

  const validateFields = () => {
    const newErrors = { password: "", confirmPassword: "" };
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{7,}$/;

    if (!password) {
      newErrors.password = "Required field";
    } else if (!passwordRegex.test(password)) {
      newErrors.password =
        "Password must be at least 7 characters long, contain one uppercase letter, one lowercase letter, and a number.";
    }

    if (!confirmPassword) {
      newErrors.confirmPassword = "Required field";
    } else if (password !== confirmPassword) {
      newErrors.confirmPassword = "Passwords do not match.";
    }

    setErrors(newErrors);

    return !newErrors.password && !newErrors.confirmPassword;
  };

  const handleSave = async () => {
    if (validateFields()) {
      const res = await createPassword({ newPassword: password });
      if (res) {
        setPasswordChanged(true);
      }
    }
  };

  useEffect(() => {
    if (passwordChanged) {
      navigate("/Portal");
    }
  }, [navigate, passwordChanged]);

  return (
    <Container>
      <Box sx={{ padding: "2rem", textAlign: "center", mb: 3 }}>
        <Typography
          variant="h5"
          component="h5"
          fontWeight="500"
          fontSize={26}
          color="#00db8f"
        >
          We received the documents you uploaded!
        </Typography>
        <Typography
          variant="h5"
          component="h5"
          sx={{ color: "#838588", fontSize: "13px", fontWeight: "500", mt: 1 }}
        >
          Thank you for completing your application with Lift Credit!*
        </Typography>
        <Typography
          variant="h5"
          component="h5"
          sx={{ color: "#838588", fontSize: "13px", fontWeight: "500", mt: 3 }}
        >
          We will verify the documents you uploaded.
        </Typography>
        <Typography
          variant="h5"
          component="h5"
          sx={{
            color: "#838588",
            fontSize: "13px",
            fontWeight: "500",
            mt: 0.5,
          }}
        >
          Your loan processor will be reaching out shortly to finalize the loan!
        </Typography>
      </Box>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography
            variant="h6"
            sx={{
              fontSize: "12px",
              fontWeight: "600",
              paddingLeft: "8px",
              marginBottom: "5px",
              color: "#838588",
            }}
          >
            Choose a password that is at least 7 characters long, contains one
            uppercase letter, one lowercase letter, and a number.{" "}
          </Typography>
          <TextField
            variant="outlined"
            value={password}
            type="password"
            onChange={(event) => setPassword(event.target.value)}
            fullWidth
            size="small"
            placeholder="Enter Password"
            error={!!errors.password}
            helperText={errors.password}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            value={confirmPassword}
            type="password"
            onChange={(event) => setConfirmPassword(event.target.value)}
            fullWidth
            size="small"
            placeholder="Confirm Password"
            error={!!errors.confirmPassword}
            helperText={errors.confirmPassword}
          />
        </Grid>
      </Grid>
      <Box display="flex" justifyContent="center" mt={6} mb={2}>
        <IconButton
          icon={<LockIcon sx={{ fontSize: "18px", color: "#00db8f" }} />}
          handleBtnClick={handleSave}
          title="SAVE & CONTINUE"
          type="contained"
        />
      </Box>
      <Typography
        variant="h5"
        component="h5"
        sx={{ color: "#838588", fontSize: "14px", fontWeight: "500", mt: 1 }}
      >
        Disclaimer{" "}
      </Typography>
      <Typography
        variant="h5"
        component="h5"
        sx={{ color: "#838588", fontSize: "14px", fontWeight: "500", mt: 1 }}
      >
        *Final approval is conditional upon verification of the information we
        have received. Additional documentation may be required for loan
        approval.
      </Typography>
    </Container>
  );
};

export default Welcome;
