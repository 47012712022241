import { AxiosResponse } from "axios";
import { apiEndPoints } from "../constants/apiEndpoints";
import axiosInstance from "../core/axios";
import { Address, LeadsRequest } from "../types/genericType";

export interface LoanRequest {
  loanTypeId: number | null;
  loanType: string;
  loanAmount: string;
  state: string;
  zip: string;
  loanRequestType: string;
  repaymentMethod: string;
  promoCode: string;
}
export interface LoanApplication {
  verification: string;
  firstName: string;
  lastName: string;
  primaryAddress: Address;
  email: string;
  monthlyIncome: string;
  dateOfBirth: string;
  phoneNumber: string;
  consentToMarketing: boolean;
  bankVerificationRequestResult: string;
  socialSecurityNumber: string;
  advertisingMethod: string;
  advertisingMethodText: null;
  licenseNumber: string;
  licenseState: string;
  timeAtAddress: string;
  optInToMarketingMessage: boolean;
  optInToReceiveTextMessage: boolean;
  incomeSources: IncomeSource[];
  bankAccounts: BankAccount[];
  bankCard: BankCard;
  loanRequest: LoanRequest;
  clarityStatus: string;
  status: string;
  loanApplicationId: string;
  redirectUrl: string;
  bankruptcyClear: boolean;
}

interface IncomeSource {
  monthlyIncome: string;
  incomeType: string;
  employer: string;
  timeAtEmployment: string;
  payDay: PayDay;
  employerAddress: EmployerAddress;
  workPhoneNumber: string;
}

interface PayDay {
  payrollType: string;
  payFrequency: string;
  payDay: string;
}

interface EmployerAddress {
  zip: string;
  city: string;
  state: string;
}

interface BankAccount {
  id: number;
  bankRoutingNumber: string;
  bankName: string;
  bankAccountNumber: string;
  bankAccountType: string;
}

interface BankCard {
  nameOnCard: string;
  cardNumber: string;
  expiration: string;
  billingAddressSameAsPrimary: boolean;
  billingAddress: Address;
}

export const createLead = async (leadData: LeadsRequest): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await axiosInstance.post(
      apiEndPoints.leads,
      leadData,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (err) {
    throw err;
  }
};

export const updateLead = async (leadData: LeadsRequest): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await axiosInstance.put(
      `${apiEndPoints.leads}/${leadData?.id}`,
      leadData,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (err) {
    throw err;
  }
};
