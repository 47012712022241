import Constants from "../constants/constant";

const formatDate = (date: Date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}T00:00:00`;
};

export const formatDateToMMDD = (date: Date) => {
  const month = date.getMonth() + 1;
  const day = date.getDate();
  return `${month.toString().padStart(2, "0")}/${day
    .toString()
    .padStart(2, "0")}`;
};

export const calculateNextPayDates = (selectedDayOfWeek: string) => {
  const today = new Date();

  const targetDayIndex = Constants.dayOfWeekOptions.findIndex(
    (option) => option.value === selectedDayOfWeek
  );

  const nextDates = [];

  for (let i = 0; i < 2; i++) {
    const nextPayDate = new Date(today);
    let dayDiff = targetDayIndex - nextPayDate.getDay();

    if (dayDiff <= 0) {
      dayDiff += 7;
    }

    nextPayDate.setDate(today.getDate() + dayDiff + 7 * i);
    nextDates.push(formatDate(nextPayDate));
  }

  return nextDates;
};

export const formatDateMinusOneDay = (dateStr: string | number | Date) => {
  const dateObj = new Date(dateStr);

  dateObj.setDate(dateObj.getDate() - 1);

  const formattedDate = `${dateObj.getDate().toString().padStart(2, "0")}/${(
    dateObj.getMonth() + 1
  )
    .toString()
    .padStart(2, "0")}/${dateObj.getFullYear()}`;

  return formattedDate;
};

export function formatDateToDDMMMYYYY(isoDateString: string | number | Date) {
  const date = new Date(isoDateString);
  const options = {
    day: "2-digit" as "2-digit",
    month: "short" as "short",
    year: "numeric" as "numeric" | "2-digit",
  };
  return date.toLocaleDateString("en-US", options);
}
