import React from "react";
import LoanAgreementViewer from "../LoanAgreement/AgreementViewer";
import LoanSignature from "./LoanSignature";

const SignLoanAgreement = () => {
  return (
    <>
      <LoanAgreementViewer isAdditionalAgreement={true} />
      <LoanSignature />
    </>
  );
};

export default SignLoanAgreement;
