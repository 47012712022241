import axios, { AxiosResponse } from "axios";
import { apiEndPoints } from "../constants/apiEndpoints";
import { User } from "../types/genericType";
import axiosInstance from "../core/axios";

interface UpdatePasswordParams {
  newPassword: string;
  currentPassword: string;
}

type UsernamePasswordPayload = {
  username: string;
  password: string;
  verification: string;
};

type TokenPayload = {
  token: string;
  credentialType: string;
};

type LoginPayload = UsernamePasswordPayload | TokenPayload;

export const login = async (
  payload: LoginPayload
): Promise<{ resource: User }> => {
  try {
    const response: AxiosResponse<{ resource: User }> = await axios.post(
      `${apiEndPoints.login}`,
      payload
    );

    return response.data;
  } catch (err) {
    throw err;
  }
};

export const forgotPassword = async (username: string): Promise<string> => {
  try {
    const response: AxiosResponse<string> = await axios.post(
      `${apiEndPoints.forgotPassword}`,
      {
        username,
      }
    );
    return response?.data;
  } catch (err: unknown) {
    if (axios.isAxiosError(err) && err.response) {
      throw err.response.data;
    }
    throw err;
  }
};

export const updatePassword = async ({
  newPassword,
  currentPassword,
}: UpdatePasswordParams): Promise<AxiosResponse<any>> => {
  try {
    const response: AxiosResponse<any> = await axiosInstance.put(
      `${apiEndPoints.credentials}`,
      {
        newPassword,
        currentPassword,
      }
    );
    return response;
  } catch (err) {
    throw err;
  }
};

export const createPassword = async (data: { newPassword: string }) => {
  try {
    const response: AxiosResponse<string> = await axios.post(
      `${apiEndPoints.credentials}`,
      { newPassword: data.newPassword }
    );
    return response?.data;
  } catch (err) {
    throw err;
  }
};

export const resetPassword = async (data: {
  newPassword: string;
  token: string;
}) => {
  try {
    const response: AxiosResponse<string> = await axios.put(
      `${apiEndPoints.forgotPassword}`,
      data
    );
    return response?.data;
  } catch (err) {
    throw err;
  }
};
