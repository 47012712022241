import React from "react";
import { Box, Typography } from "@mui/material";
import { ErrorDetails } from "../../types/genericType";

interface SorryNotEligibleProps {
  error?: ErrorDetails;
}

const SorryNotEligible: React.FC<SorryNotEligibleProps> = ({ error }) => {
  return (
    <Box sx={{ padding: "2rem", textAlign: "center" }}>
      <Typography variant="h5" component="h5" fontWeight="bold" color="#00db8f">
        Sorry, you are not eligible to request a loan{" "}
      </Typography>
      <Typography
        variant="h5"
        component="h5"
        sx={{ color: "#838588", fontSize: "12px", fontWeight: "400" }}
      >
        You do not meet the following requirement
      </Typography>

      <Box sx={{ marginTop: "2rem" }}>
        <Typography
          sx={{ color: "#838588", fontSize: "16px", fontWeight: "500" }}
          mb={2}
        >
          {error?.message}
        </Typography>
      </Box>
    </Box>
  );
};

export default SorryNotEligible;
