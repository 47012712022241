import { Box, Link, Typography } from "@mui/material";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";

const BankVerificationProcessError = () => {
  const navigate = useNavigate();
  const { requestCode } = useParams();

  const gotoBankVerificationProcess = () => {
    navigate(`/bankVerificationProcessSuccess/${requestCode}`);
  };

  const skipBankVerification = () => {
    navigate("/upload");
  };

  return (
    <Box sx={{ padding: "2rem", textAlign: "center" }}>
      <Typography fontSize={28} fontWeight="500" color="#00db8f">
        We were unable to verify your banking information. One of our Loan
        Specialists will be contacting you shortly to further assist you. If
        you’d like to expedite the process, please contact 1-800-223-5858 or
        email us at Support@LiftCredit.com
      </Typography>
      <Typography
        sx={{ color: "#838588", fontSize: "16px", fontWeight: "400", mt: 4 }}
      >
        Click{" "}
        <Link
          href="#"
          underline="none"
          sx={{
            cursor: "pointer",
            color: "#00db8f",
            borderBottom: "1px solid #00db8f",
            "&:hover": {
              color: "#00db8f",
              borderBottom: "1px solid #00db8f",
            },
          }}
          onClick={() => {
            gotoBankVerificationProcess();
          }}
        >
          here
        </Link>{" "}
        to try again
      </Typography>
      <Typography
        sx={{ color: "#838588", fontSize: "16px", fontWeight: "400", mt: 4 }}
      >
        Or manually upload your income documents{" "}
        <Link
          href="#"
          underline="none"
          sx={{
            cursor: "pointer",
            color: "#00db8f",
            borderBottom: "1px solid #00db8f",
            "&:hover": {
              color: "#00db8f",
              borderBottom: "1px solid #00db8f",
            },
          }}
          onClick={() => {
            skipBankVerification();
          }}
        >
          here
        </Link>
      </Typography>
    </Box>
  );
};

export default BankVerificationProcessError;
