import { AxiosResponse } from "axios";
import axiosInstance from "../core/axios";
import { apiEndPoints } from "../constants/apiEndpoints";

export const getBankVerificationRequestCode = async (
  loanId: String
): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await axiosInstance.post(
      `${apiEndPoints.bankVerification}/requestCode/${loanId}`
    );
    return response?.data;
  } catch (err) {
    throw err;
  }
};

export const getBankVerificationFailedAttempts = async (loanId: String) => {
  try {
    const response: AxiosResponse<any> = await axiosInstance.get(
      `${apiEndPoints.bankVerification}/${loanId}/failedattemptscount`
    );
    return response?.data;
  } catch (err) {
    throw err;
  }
};

export const getBankVerificationRequestCodeStatus = async (loanId: String) => {
    try {
      const response: AxiosResponse<any> = await axiosInstance.get(
        `${apiEndPoints.bankVerification}/${loanId}/requestcodestatus`
      );
      return response?.data;
    } catch (err) {
      throw err;
    }
  };

  export const getChirpLinkToken = async (loanId: String) => {
    try {
      const response: AxiosResponse<any> = await axiosInstance.get(
        `${apiEndPoints.bankVerification}/${loanId}/chirplink`
      );
      return response?.data;
    } catch (err) {
      throw err;
    }
  }

  export const getLokyataScoreData = async (loanId: String) => {
    try {
      const response: AxiosResponse<any> = await axiosInstance.get(
        `${apiEndPoints.bankVerification}/${loanId}/lokyatascore`
      );
      return response?.data;
    } catch (err) {
      throw err;
    }
  }