import React, { FC, useEffect, useState } from "react";
import FileUploadField from "../common/FileUploadField";
import { Checkbox, FormControlLabel, Typography } from "@mui/material";
import { LoanDocuments, UploadDocsProps } from "../../types/genericType";

const UploadDocs: FC<UploadDocsProps> = ({
  loanDocuments,
  requireSecondProofOfIncome,
  allowUseExistingPhotoId,
  onChange,
}) => {
  const [useExistingPhotoId, setUseExistingPhotoId] = useState(
    allowUseExistingPhotoId
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleChange = (change: any, controlId: keyof LoanDocuments) => {
    if (onChange) {
      onChange(change, controlId);
    }
  };

  useEffect(() => {
    handleChange(useExistingPhotoId, "useExistingPhotoId");
  }, [handleChange, useExistingPhotoId]);

  return (
    <form>
      {!requireSecondProofOfIncome && (
        <Typography
          variant="h5"
          component="h5"
          sx={{ color: "#838588", fontSize: "11px", fontWeight: "400", mt: 6 }}
        >
          Acceptable document formats are image files (.jpg or .png) or pdf
          documents (.pdf)
        </Typography>
      )}
      <Typography
        variant="subtitle1"
        gutterBottom
        sx={{
          mt: 2,
          mb: 2,
          fontSize: "14px",
          fontWeight: "500",
          color: "#838588",
        }}
      >
        Proof of Income
      </Typography>
      <FileUploadField
        id="proofOfIncome1"
        label="Proof of Income"
        documentType="ProofOfIncome1"
        hint="Proof of Income 1"
        onChange={handleChange}
        value={loanDocuments.proofOfIncome1}
        required
      />
      {requireSecondProofOfIncome && (
        <FileUploadField
          id="proofOfIncome2"
          documentType="ProofOfIncome2"
          hint="Proof of Income 2"
          onChange={handleChange}
          value={loanDocuments.proofOfIncome2}
          required
        />
      )}

      {!requireSecondProofOfIncome && (
        <Typography
          variant="h5"
          component="h5"
          sx={{ color: "#838588", fontSize: "11px", fontWeight: "400" }}
        >
          If you specified your income source as "Employment", please provide{" "}
          {requireSecondProofOfIncome ? "two" : "one"} of your most recent pay
          stubs. Images must show the full paystub and cannot be blurry. If you
          specified your income source as "Retirement", "Disability" or "Social
          Security", provide a full awards letter. If you specified your income
          source as "Self Employed", please provide two months of bank
          statements.
        </Typography>
      )}
      {requireSecondProofOfIncome && (
        <Typography
          variant="h5"
          component="h5"
          sx={{ color: "#838588", fontSize: "11px", fontWeight: "400", mt: 6 }}
        >
          <div>
            <p className="info-text">
              For these Income Sources, upload the following: (Cannot be blurry)
            </p>
            <ul className="bulleted-list">
              <li className="info-text">
                Employed: <u>Two most recent pay stubs</u>
              </li>
              <li className="info-text">
                Retirement, Disability, Social Security:{" "}
                <u>Full Awards Letter</u>
              </li>
              <li className="info-text">
                Self Employed: <u>Two months bank statements</u>
              </li>
            </ul>
          </div>
        </Typography>
      )}

      <Typography
        variant="subtitle1"
        gutterBottom
        sx={{
          mt: 2,
          fontSize: "14px",
          fontWeight: "500",
          color: "#838588",
        }}
      >
        Identification
      </Typography>
      <FormControlLabel
        control={
          <Checkbox
            checked={useExistingPhotoId}
            onChange={() => {
              setUseExistingPhotoId(!useExistingPhotoId);
            }}
          />
        }
        sx={{
          display: "flex",
          alignItems: "center",
        }}
        label={
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: "normal",
              color: "grey",
            }}
          >
            Use identification already on file
          </Typography>
        }
      />
      {!useExistingPhotoId && (
        <FileUploadField
          id="photoId"
          documentType="PhotoId"
          hint="Upload Photo ID"
          onChange={handleChange}
          value={loanDocuments.photoId}
          required
        />
      )}
      <Typography
        variant="h5"
        component="h5"
        sx={{ color: "#838588", fontSize: "11px", fontWeight: "400", mt: 6 }}
      >
        Please provide a color photo of your personal identification. Snap a
        picture from your camera phone and upload it here, or you can email it
        to support@liftcredit.com.
      </Typography>
    </form>
  );
};

export default UploadDocs;
