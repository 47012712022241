import { Box, Button, Link, Typography } from "@mui/material";
import React from "react";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import HelpBankVerification from "../Modals/HelpBankVerification";
import { useNavigate } from "react-router-dom";

const BankVerification = () => {
  const [infoModal, setInfoModal] = React.useState(false);
  const navigate = useNavigate();
  const customerDetails = JSON.parse(
    sessionStorage.getItem("customerDetails") || "{}"
  );
  return (
    <Box sx={{ padding: "2rem", textAlign: "center" }}>
      <Typography fontSize={28} fontWeight="500" color="#00db8f">
        Thank you for applying, {customerDetails?.firstName}{" "}
        {customerDetails?.lastName}!
      </Typography>
      <Typography
        sx={{ color: "#838588", fontSize: "16px", fontWeight: "400", mt: 4 }}
      >
        The next step is to verify your income. Please click "VERIFY BANK" to
        connect the bank account where you receive your income.
      </Typography>
      <Button
        variant="contained"
        sx={{
          backgroundColor: "#004d40",
          color: "#fff",
          fontSize: "18px",
          textTransform: "capitalize",
          mt: 4,
          "&:hover": {
            backgroundColor: "#004d40",
          },
        }}
        onClick={() => {
          navigate("/Portal/bankVerificationProcess");
        }}
      >
        VERIFY BANK <ChevronRightIcon />
      </Button>
      <Typography sx={{ mt: 4, fontSize: "14px" }}>
        <Link
          href="#"
          underline="none"
          sx={{
            cursor: "pointer",
            color: "#00db8f",
            borderBottom: "1px solid #00db8f",
            "&:hover": {
              color: "#00db8f",
              borderBottom: "1px solid #00db8f",
            },
          }}
          onClick={() => {
            setInfoModal(true);
          }}
        >
          Why do we need this?{" "}
        </Link>
      </Typography>
      <Typography sx={{ mt: 4, fontSize: "14px" }}>
        I want to{" "}
        <Link
          href="#"
          underline="none"
          sx={{
            cursor: "pointer",
            color: "#00db8f",
            borderBottom: "1px solid #00db8f",
            "&:hover": {
              color: "#00db8f",
              borderBottom: "1px solid #00db8f",
            },
          }}
          onClick={() => {
            navigate("/Portal/upload");
          }}
        >
          verify my income with physical pay documents
        </Link>{" "}
        (May take 1-2 business days)
      </Typography>
      <HelpBankVerification
        open={infoModal}
        handleClose={() => setInfoModal(false)}
      />
    </Box>
  );
};

export default BankVerification;
