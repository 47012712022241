import React, { useState, useEffect } from "react";
import PaidLoan from "./PaidLoan";
import ActiveLoan from "./ActiveLoan";
import DeniedLoan from "./DeniedLoan";
import PendingLoan from "./PendingLoan";
import WithdrawnLoan from "./WithdrawnLoan";
import {
  getCurrentLoanStatus,
} from "../../services/loanPortalService";
import Loader from "../../components/loader/Loader";
import { getRewardsDetails } from "../../services/rewardsService";
import { getCustomerDetails, getCustomerStatus } from "../../services/customerService";

const LoanPortal = () => {
  const [loanStatus, setLoanStatus] = useState<string | null>(null);
  const [customerLoanStatus, setCustomerLoanStatus] = useState<any | null>(
    null
  );
  const [currentLoanStatus, setCurrentLoanStatus] = useState<any | null>(null);
  const [rewardsDetails, setRewardsDetails] = useState<any | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchCustomerDetails = async () => {
    try {
      setIsLoading(true);
      const customerDetails = await getCustomerDetails();
      sessionStorage.setItem(
        "customerDetails",
        JSON.stringify(customerDetails)
      );
    } catch (error) {
      console.error("Error fetching loan data:", error);
    }
  };

  useEffect(() => {
    const fetchLoanStatus = async () => {
      try {
        setIsLoading(true);
        const customerStatus = await getCustomerStatus();
        const rewardsDetails = await getRewardsDetails();
        const currentLoanStatus = await getCurrentLoanStatus();
        sessionStorage.setItem(
          "rewardsDetails",
          JSON.stringify(rewardsDetails)
        );
        if (customerStatus) {
          setCustomerLoanStatus(customerStatus?.loanStatus);
          const status = customerStatus?.loanStatus?.current?.status;
          setLoanStatus(status);
          setIsLoading(false);
        }
        currentLoanStatus && setCurrentLoanStatus(currentLoanStatus);
        rewardsDetails && setRewardsDetails(rewardsDetails);
      } catch (error) {
        console.error("Error fetching loan data:", error);
      }
    };

    fetchLoanStatus();
    fetchCustomerDetails();
  }, []);

  const renderLoanContent = () => {
    switch (loanStatus) {
      case "Active":
      case "Past Due":
        return (
          <ActiveLoan
            currentLoanStatus={currentLoanStatus}
            rewardsDetails={rewardsDetails}
          />
        );
      case "Paid in Full":
      case "Paid In Full Settlement":
        return (
          <PaidLoan
            currentLoanStatus={currentLoanStatus}
            rewardsDetails={rewardsDetails}
          />
        );
      case "Denied":
        return <DeniedLoan currentLoanStatus={currentLoanStatus} />;
      case "Withdrawn":
        return <WithdrawnLoan currentLoanStatus={currentLoanStatus} />;
      default:
        return <Loader message="" />;
    }
  };

  return isLoading ? (
    <Loader message="" />
  ) : (
    <>
      {customerLoanStatus?.pending && (
        <PendingLoan customerLoanStatus={customerLoanStatus} />
      )}
      {renderLoanContent()}
    </>
  );
};

export default LoanPortal;
