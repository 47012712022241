import React from "react";
import { Box, Container, Typography } from "@mui/material";

interface SorryDenialLendingPartnerPageProps {
  firstName: string;
}

const SorryDenialLendingPartnerPage: React.FC<
  SorryDenialLendingPartnerPageProps
> = ({ firstName }) => {
  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
      <Box textAlign="center" mb={3}>
        <Typography
          variant="h5"
          component="h5"
          fontWeight="bold"
          color="#00db8f"
        >
          We're Sorry!
        </Typography>
      </Box>

      <Box sx={{ marginTop: "3rem" }}>
        <Typography
          variant="h5"
          sx={{ color: "#838588", fontSize: "16px", fontWeight: "400" }}
        >
          Sorry, {firstName}
        </Typography>
        <Typography
          variant="h6"
          sx={{ color: "#838588", fontSize: "16px", fontWeight: "400", mt: 3 }}
        >
          We were unable to connect you with a lender in our partner network.
        </Typography>
        <Typography
          variant="h5"
          sx={{ color: "#838588", fontSize: "16px", fontWeight: "400", mt: 3 }}
        >
          Thanks for applying!
        </Typography>
      </Box>
    </Container>
  );
};

export default SorryDenialLendingPartnerPage;
