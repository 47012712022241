import {
  Box,
  Button,
  Container,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState, useContext } from "react";
import IconBtn from "../IconBtn/IconBtn";
import LockIcon from "@mui/icons-material/Lock";
import Recaptcha from "../recaptcha/Recaptcha";
import { login } from "../../services/authService";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../loader/Loader";
import ErrorComp from "../errorComp/ErrorComp";
import { User } from "../../types/genericType";
import { AuthContext } from "../../core/authContext";
import BackDrop from "../common/BackDrop";

const Login = () => {
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [captchaToken, setCaptchaToken] = useState<string>("");
  const [isErrorVisible, setIsErrorVisible] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const [redirectFlow, setRedirectFlow] = useState(false);
  const [errors, setErrors] = useState({
    userName: "",
    password: "",
    captcha: "",
  });
  const { setIsSigningOut } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const credentialType = searchParams.get("credentialType");
    const tokenFromURL = searchParams.get("token");

    if (credentialType === "Token" && tokenFromURL) {
      setRedirectFlow(true);
      setIsLoading(true);
      loginUser(tokenFromURL);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (userName) {
      setErrors({ ...errors, userName: "" });
    }
    if (password) {
      setErrors({ ...errors, password: "" });
    }
    if (captchaToken) {
      setErrors({
        ...errors,
        captcha: "",
      });
    }
  }, [userName, password, errors, captchaToken]);

  const loginUser = async (token?: string) => {
    setIsLoading(true);
    try {
      const data = token
        ? await login({ token, credentialType: "token" })
        : await login({
            username: userName,
            password,
            verification: captchaToken,
          });
      if (data) {
        const userData: User = data["resource"];
        localStorage.setItem("user", JSON.stringify(userData));
        if (userData) {
          setIsLoading(false);
          return userData?.role === "Admin"
            ? navigate("/admin")
            : navigate("/Portal");
        }
      }
    } catch (error) {
      setIsErrorVisible(true);
      setIsLoading(false);
    }
  };

  const handleLogin = async () => {
    setIsSigningOut(false);
    let hasErrors = false;
    const newErrors = { userName: "", password: "", captcha: "" };

    if (!userName) {
      newErrors.userName = "Required field";
      hasErrors = true;
    }

    if (!password) {
      newErrors.password = "Required field";
      hasErrors = true;
    }

    if (!captchaToken) {
      newErrors.captcha = "Please verify you are not a robot.";
      hasErrors = true;
    }

    setErrors(newErrors);

    if (!hasErrors) {
      loginUser();
    }
  };

  return redirectFlow ? (
    <Loader />
  ) : (
    <Container>
      {isErrorVisible && (
        <ErrorComp
          message={
            "Username or password is incorrect or verification is invalid."
          }
          onClose={() => setIsErrorVisible(false)}
          detailsRoute="/sorry"
        />
      )}
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography
            variant="h6"
            sx={{
              fontSize: "12px",
              fontWeight: "600",
              paddingLeft: "8px",
              marginBottom: "5px",
              color: "#838588",
            }}
          >
            Username
          </Typography>
          <TextField
            variant="outlined"
            value={userName}
            onChange={(event) => setUserName(event.target.value)}
            fullWidth
            size="small"
            placeholder="Enter Username or Email"
            error={!!errors.userName}
            helperText={errors.userName}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="h6"
            sx={{
              fontSize: "12px",
              fontWeight: "600",
              paddingLeft: "8px",
              marginBottom: "5px",
              color: "#838588",
            }}
          >
            Password
          </Typography>
          <TextField
            variant="outlined"
            type="password"
            value={password}
            onChange={(event) => setPassword(event.target.value)}
            fullWidth
            size="small"
            placeholder="Enter Password"
            error={!!errors.password}
            helperText={errors.password}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="h6"
            sx={{
              fontSize: "14px",
              fontWeight: "600",
              paddingLeft: "8px",
              marginBottom: "5px",
              color: "#838588",
            }}
          >
            Verification
          </Typography>
          <Recaptcha setCaptchaToken={setCaptchaToken} />
          {errors.captcha && (
            <Typography color="error" variant="caption">
              {errors.captcha}
            </Typography>
          )}
        </Grid>
      </Grid>
      <Box display="flex" justifyContent="center" mt={4}>
        <IconBtn
          icon={<LockIcon sx={{ fontSize: "18px", color: "#00db8f" }} />}
          handleBtnClick={handleLogin}
          title="SIGN IN"
          type="contained"
        />
      </Box>{" "}
      <Box display="flex" justifyContent="center" mt={2}>
        <Button
          variant="text"
          onClick={() => {
            navigate("/forgot-password");
          }}
          sx={{
            color: "#00db8f",
            textTransform: "none",
            fontSize: "16px",
            fontWeight: "600",
          }}
        >
          Forgot Password
        </Button>
      </Box>
      <BackDrop
        loading={isLoading}
        message="Signing you into your personal Lift Credit portal..."
      />
    </Container>
  );
};

export default Login;
