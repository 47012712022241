import {
  Box,
  Button,
  Container,
  Grid,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import IconBtn from "../IconBtn/IconBtn";
import LockIcon from "@mui/icons-material/Lock";
import { useLocation, useNavigate } from "react-router-dom";
import { resetPassword } from "../../services/authService";
import ErrorComp from "../errorComp/ErrorComp";

const ResetPassword = () => {
  const [password, setPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [passwordChanged, setPasswordChanged] = React.useState(false);
  const [errors, setErrors] = React.useState({
    password: "",
    confirmPassword: "",
  });
  const [apiError, setApiError] = React.useState("");
  const location = useLocation();

  const navigate = useNavigate();

  const validateFields = () => {
    const newErrors = { password: "", confirmPassword: "" };
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{7,}$/;

    if (!password) {
      newErrors.password = "Required field";
    }

    if (!confirmPassword) {
      newErrors.confirmPassword = "Required field";
    } else if (password !== confirmPassword) {
      newErrors.confirmPassword = "Passwords do not match.";
    } else if (!passwordRegex.test(confirmPassword)) {
      newErrors.confirmPassword =
        "Password must be at least 7 characters long, contain one uppercase letter, one lowercase letter, and a number.";
    }

    setErrors(newErrors);

    return !newErrors.password && !newErrors.confirmPassword;
  };

  const handleSave = async () => {
    const searchParams = new URLSearchParams(location.search);
    const tokenFromURL = searchParams.get("token");
    if (validateFields() && tokenFromURL) {
      try {
        const data = await resetPassword({
          newPassword: password,
          token: tokenFromURL,
        });
        if (data) {
          setApiError("");
          setPasswordChanged(true);
        }
      } catch (error) {
        setApiError(
          (error as Error)?.message || "An unexpected error occurred"
        );
      }
    }
  };

  return (
    <Container>
      {apiError && (
        <ErrorComp message={apiError} onClose={() => setApiError("")} />
      )}
      <Box sx={{ textAlign: "center", marginBottom: "2rem" }}>
        <Typography
          variant="subtitle1"
          sx={{ color: "#00db8f", fontSize: "24px", fontWeight: 500 }}
        >
          {passwordChanged ? "Password Changed" : "Reset Password"}
        </Typography>
        <Typography variant="body2" sx={{ color: "#838588" }}>
          {passwordChanged
            ? "Your password was updated successfully!"
            : "Change your password to something you'll remember."}
        </Typography>
      </Box>
      {passwordChanged ? (
        <>
          <Typography variant="body2" sx={{ color: "#838588", mb: 20 }}>
            Your password has been reset. Continue to login{" "}
            <Link
              href="#"
              underline="always"
              sx={{
                cursor: "pointer",
                color: "#00db8f",
                "&:hover": {
                  textDecoration: "underline",
                  color: "#00db8f",
                },
              }}
              onClick={() => {
                navigate("/login");
              }}
            >
              here
            </Link>
            .
          </Typography>
          <Box display="flex" justifyContent="center" mt={2}>
            <Button
              variant="text"
              onClick={() => {
                navigate("/login");
              }}
              sx={{
                color: "#00db8f",
                textTransform: "none",
                fontSize: "16px",
                fontWeight: "600",
              }}
            >
              Return to Login
            </Button>
          </Box>
        </>
      ) : (
        <>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography
                variant="h6"
                sx={{
                  fontSize: "12px",
                  fontWeight: "600",
                  paddingLeft: "8px",
                  marginBottom: "5px",
                  color: "#838588",
                }}
              >
                Choose a password that is at least 7 characters long, contains
                one uppercase letter, one lowercase letter, and a number.{" "}
              </Typography>
              <TextField
                variant="outlined"
                value={password}
                type="password"
                onChange={(event) => setPassword(event.target.value)}
                fullWidth
                size="small"
                placeholder="Enter Password"
                error={!!errors.password}
                helperText={errors.password}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                value={confirmPassword}
                type="password"
                onChange={(event) => setConfirmPassword(event.target.value)}
                fullWidth
                size="small"
                placeholder="Confirm Password"
                error={!!errors.confirmPassword}
                helperText={errors.confirmPassword}
              />
            </Grid>
          </Grid>
          <Box display="flex" justifyContent="center" mt={4}>
            <IconBtn
              icon={<LockIcon sx={{ fontSize: "18px", color: "#00db8f" }} />}
              handleBtnClick={handleSave}
              title="Change Password"
              type="contained"
            />
          </Box>
        </>
      )}
    </Container>
  );
};

export default ResetPassword;
