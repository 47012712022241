import React, { useEffect } from "react";

const RedirectToPortal = ({ redirectUrl = "" }) => {
  useEffect(() => {
    if (redirectUrl) {
      window.location.href = redirectUrl;
    }
  }, [redirectUrl]);

  return (
    <div>
      <h1 className="page-header-text header-text-color">
        <b>Please wait. Your application is being processed.</b>
      </h1>
      <div className="info-page-content center"></div>
    </div>
  );
};
export default RedirectToPortal;
