import React, { useState, useCallback, useEffect } from "react";
import {
  TextField,
  Container,
  Grid,
  Typography,
  Box,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
  IconButton,
  InputAdornment,
} from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import ClearIcon from "@mui/icons-material/Clear";
import Dropdown from "../../components/common/Dropdown";
import Constants from "../../constants/constant";
import { Address, BankDataType } from "../../types/genericType";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import IconBtn from "../../components/IconBtn/IconBtn";
import SampleCheckModal from "../Modals/SampleCheckModal";
import { getBankInfo, validateBankInfo } from "../../services/bankService";
import { isValid } from "../../utils/utils";
import AddressForm from "../../components/addressForm/AddressForm";

interface StepFourProps {
  prevStep: () => void;
  nextStep: () => void;
}

const StepFour = ({ prevStep, nextStep }: StepFourProps) => {
  const [open, setOpen] = useState(false);
  const [hasDebitCard, setHasDebitCard] = useState<boolean>(true);

  const initialBankAccount: BankDataType = {
    id: "bankAccount1",
    bankRoutingNumber: "",
    bankAccountNumber: "",
    bankName: "",
    bankAccountType: "",
  };

  const [formData, setFormData] = useState({
    bankAccounts: [initialBankAccount],
    nameOnCard: "",
    cardNumber: "",
    expiration: "",
    securityCode: "",
    billingAddressSameAsPrimary: true,
    billingAddress: {
      addressLine1: "",
      zip: "",
      city: "",
      state: "",
    },
  });

  const [errors, setErrors] = useState({
    bankAccounts: [
      {
        bankRoutingNumber: "",
        bankAccountNumber: "",
        bankName: "",
        bankAccountType: "",
      },
    ],
    nameOnCard: "",
    cardNumber: "",
    expiration: "",
    securityCode: "",
    billingAddress: {
      addressLine1: "",
      zip: "",
      city: "",
      state: "",
    },
  });

  useEffect(() => {
    const storedData = sessionStorage.getItem("LoanApplication");
    if (storedData) {
      try {
        const parsedData = JSON.parse(storedData);
        setFormData((prevData) => ({
          ...parsedData,
          bankAccounts: parsedData.bankAccounts || prevData.bankAccounts,
          nameOnCard: parsedData.nameOnCard || prevData.nameOnCard,
          cardNumber: parsedData.cardNumber || prevData.cardNumber,
          expiration: parsedData.expiration || prevData.expiration,
          securityCode: parsedData.securityCode || prevData.securityCode,
          billingAddressSameAsPrimary:
            parsedData.billingAddressSameAsPrimary ||
            prevData.billingAddressSameAsPrimary,
          billingAddress: parsedData.billingAddress || prevData.billingAddress,
        }));
        setHasDebitCard(parsedData.hasDebitCard || hasDebitCard);
      } catch (error) {
        console.error("Error parsing storedData from sessionStorage:", error);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateSessionStorage = (
    data: Partial<{
      bankAccounts: BankDataType[];
      bankCard: {
        nameOnCard: string;
        cardNumber: string;
        expiration: string;
        securityCode: string;
        billingAddressSameAsPrimary: boolean;
        billingAddress: Address | null;
      } | null;
      hasDebitCard: boolean;
    }>
  ) => {
    const existingData = sessionStorage.getItem("LoanApplication");
    const existingDataObj = existingData ? JSON.parse(existingData) : {};
    const updatedData = {
      ...existingDataObj,
      ...data,
    };
    sessionStorage.setItem("LoanApplication", JSON.stringify(updatedData));
  };

  useEffect(() => {
    updateSessionStorage({
      bankAccounts: formData.bankAccounts,
      bankCard: hasDebitCard ? {
        nameOnCard: formData.nameOnCard,
        cardNumber: formData.cardNumber,
        expiration: formData.expiration,
        securityCode: formData.securityCode,
        billingAddressSameAsPrimary: formData.billingAddressSameAsPrimary,
        billingAddress: formData.billingAddressSameAsPrimary ? null : formData.billingAddress,
      } : null,
      hasDebitCard: hasDebitCard
    });
  }, [formData, hasDebitCard]);

  const validate = useCallback(() => {
    const newErrors: any = {};
    formData.bankAccounts.forEach((account, index) => {
      const accountErrors: any = {};
      accountErrors.bankRoutingNumber = account.bankRoutingNumber
        ? ""
        : "Required field";
      accountErrors.bankAccountNumber = account.bankAccountNumber
        ? ""
        : "Required field";
      accountErrors.bankName = account.bankName ? "" : "Required field";
      accountErrors.bankAccountType = account.bankAccountType
        ? ""
        : "Required field";
      if (Object.keys(accountErrors).length > 0) {
        newErrors.bankAccounts = newErrors.bankAccounts || [];
        newErrors.bankAccounts[index] = accountErrors;
      }
    });

    if (hasDebitCard) {
      newErrors.nameOnCard = !formData.nameOnCard ? "Required field" : "";
      const cardNumberRegex = /^\d{15,16}$/;
      const sanitizedCardNumber = formData.cardNumber.replace(/\D/g, "");
      newErrors.cardNumber = !formData.cardNumber
        ? "Required field"
        : !cardNumberRegex.test(sanitizedCardNumber)
        ? "Invalid card number"
        : "";
      newErrors.expiration = !formData.expiration
        ? "Required field"
        : !/^(0[1-9]|1[0-2])\/\d{2}$/.test(formData.expiration)
        ? "Invalid expiration date"
        : "";
      newErrors.securityCode = !formData.securityCode
        ? "Required field"
        : !/^\d{3,4}$/.test(formData.securityCode)
        ? "Invalid CVV"
        : "";
      if (!formData.billingAddressSameAsPrimary) {
        newErrors.billingAddress = {
          addressLine1: !formData.billingAddress.addressLine1
            ? "Required field"
            : "",
          zip: !formData.billingAddress.zip ? "Required field" : "",
          city: !formData.billingAddress.city ? "Required field" : "",
          state: !formData.billingAddress.state ? "Required field" : "",
        };
      }
    }

    setErrors(newErrors);
    const validationResult = isValid(newErrors);
    return validationResult;
  }, [formData, hasDebitCard]);

  const handleNext = async () => {
    if (validate()) {
      const validBankInfo = await validateBankInfo({
        routingNumber: formData?.bankAccounts[0]?.bankRoutingNumber,
        accountNumber: formData?.bankAccounts[0]?.bankAccountNumber,
        bankName: formData?.bankAccounts[0]?.bankName,
      });
      if (validBankInfo?.allow) {
        nextStep();
      }
    }
  };

  const handleBankAccountChange = async (
    index: number,
    field: string,
    value: string
  ) => {
    const updatedBankAccounts = formData.bankAccounts.map((account, i) =>
      i === index ? { ...account, [field]: value } : account
    );

    setFormData({ ...formData, bankAccounts: updatedBankAccounts });

    if (field === "bankRoutingNumber") {
      const bankName = await getBankInfo(value);
      updatedBankAccounts[index].bankName = bankName;
      setFormData({ ...formData, bankAccounts: updatedBankAccounts });
    }

    const updatedErrors = errors.bankAccounts.map((error, i) =>
      i === index ? { ...error, [field]: "" } : error
    );
    setErrors({ ...errors, bankAccounts: updatedErrors });
  };

  const handleCardChange = (field: string, value: string | boolean) => {
    let formattedValue = value;

    if (field === "cardNumber") {
      formattedValue = value
        ?.toString()
        .replace(/\D/g, "")
        .replace(/(\d{4})(?=\d)/g, "$1-")
        .substring(0, 19);
    } else if (field === "expiration") {
      formattedValue = value
        ?.toString()
        .replace(/\D/g, "")
        .replace(/(\d{2})(?=\d)/g, "$1/")
        .substring(0, 5);
    } else if (field === "securityCode") {
      formattedValue = value?.toString().replace(/\D/g, "").substring(0, 4);
    } else if (field === "billingAddressSameAsPrimary") {
      formattedValue = value;
    }

    setFormData({ ...formData, [field]: formattedValue });
    setErrors({ ...errors, [field]: "" });
  };

  const handleAddBankAccount = () => {
    setFormData({
      ...formData,
      bankAccounts: [
        ...formData.bankAccounts,
        {
          ...initialBankAccount,
          id: `bankAccount${formData.bankAccounts.length + 1}`,
        },
      ],
    });
    setErrors({
      ...errors,
      bankAccounts: [
        ...errors.bankAccounts,
        {
          bankRoutingNumber: "",
          bankAccountNumber: "",
          bankName: "",
          bankAccountType: "",
        },
      ],
    });
  };

  const handleRemoveBankAccount = (index: number) => {
    setFormData({
      ...formData,
      bankAccounts: formData.bankAccounts.filter((_, i) => i !== index),
    });
    setErrors({
      ...errors,
      bankAccounts: errors.bankAccounts.filter((_, i) => i !== index),
    });
  };

  const handleAddDebitCard = () => {
    setHasDebitCard(true);
  };

  const handleRemoveDebitCard = () => {
    setHasDebitCard(false);
    setFormData({
      ...formData,
      nameOnCard: "",
      cardNumber: "",
      expiration: "",
      securityCode: "",
    });
    setErrors({
      ...errors,
      nameOnCard: "",
      cardNumber: "",
      expiration: "",
      securityCode: "",
    });
  };

  const handleAddressChange = (change: { id: string; value: string }) => {
    setFormData((prevData) => ({
      ...prevData,
      billingAddress: {
        ...prevData.billingAddress,
        [change.id]: change.value,
      },
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      primaryAddress: {
        ...prevErrors.billingAddress,
        [change.id]: "",
      },
    }));
  };

  return (
    <Container>
      <Grid container spacing={2}>
        {formData.bankAccounts.map((bankAccount, index) => (
          <React.Fragment key={index}>
            {index > 0 && (
              <Grid item xs={12}>
                <Box
                  display="flex"
                  justifyContent="start"
                  alignItems="center"
                  sx={{
                    color: "#00db8f",
                    fontWeight: 500,
                    cursor: "pointer",
                    "&:hover": {
                      color: "#00594f",
                    },
                  }}
                  onClick={() => handleRemoveBankAccount(index)}
                >
                  <ClearIcon fontSize="small" />
                  <Typography
                    sx={{
                      fontFamily: "AvenirNext-DemiBold",
                      marginLeft: "4px",
                    }}
                  >
                    Remove Bank Account
                  </Typography>
                </Box>
              </Grid>
            )}
            <Grid item xs={6}>
              <Typography
                variant="h6"
                sx={{
                  fontSize: "12px",
                  fontWeight: "600",
                  paddingLeft: "8px",
                  marginBottom: "5px",
                  color: "#838588",
                }}
              >
                Routing Number
              </Typography>
              <TextField
                variant="outlined"
                value={bankAccount.bankRoutingNumber}
                onChange={(e) =>
                  handleBankAccountChange(
                    index,
                    "bankRoutingNumber",
                    e.target.value
                  )
                }
                fullWidth
                size="small"
                placeholder="Enter Routing Number"
                error={!!errors.bankAccounts?.[index]?.bankRoutingNumber}
                helperText={errors?.bankAccounts?.[index]?.bankRoutingNumber}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setOpen(true)}>
                        <HelpOutlineIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography
                variant="h6"
                sx={{
                  fontSize: "12px",
                  fontWeight: "600",
                  paddingLeft: "8px",
                  marginBottom: "5px",
                  color: "#838588",
                }}
              >
                Account Number
              </Typography>
              <TextField
                variant="outlined"
                value={bankAccount.bankAccountNumber}
                onChange={(e) =>
                  handleBankAccountChange(
                    index,
                    "bankAccountNumber",
                    e.target.value
                  )
                }
                fullWidth
                size="small"
                placeholder="Enter Account Number"
                error={!!errors.bankAccounts[index]?.bankAccountNumber}
                helperText={errors.bankAccounts[index]?.bankAccountNumber}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setOpen(true)}>
                        <HelpOutlineIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="h6"
                sx={{
                  fontSize: "12px",
                  fontWeight: "600",
                  paddingLeft: "8px",
                  marginBottom: "5px",
                  color: "#838588",
                }}
              >
                Bank Name
              </Typography>
              <TextField
                variant="outlined"
                value={bankAccount.bankName}
                onChange={(e) =>
                  handleBankAccountChange(index, "bankName", e.target.value)
                }
                fullWidth
                size="small"
                placeholder="Enter Bank Name"
                error={!!errors.bankAccounts[index]?.bankName}
                helperText={errors.bankAccounts[index]?.bankName}
              />
            </Grid>
            <Grid item xs={12}>
              <Dropdown
                id="bankAccountType"
                label="Account Type"
                value={bankAccount.bankAccountType || ""}
                options={Constants.bankAccountTypeOptions.map((option) => ({
                  value: option.value,
                  label: option.name,
                }))}
                onChange={(e) =>
                  handleBankAccountChange(index, "bankAccountType", e)
                }
                required={true}
                error={errors.bankAccounts[index]?.bankAccountType}
              />
            </Grid>
          </React.Fragment>
        ))}
        <Grid item xs={12}>
          <Typography
            variant="body2"
            sx={{
              fontSize: "12px",
              color: "#838588",
            }}
          >
            We require your bank information because we typically send funds
            through ACH deposit, and ACH may also be used as a form of payment.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Box
            display="flex"
            justifyContent="start"
            alignItems="center"
            sx={{
              color: "#00db8f",
              fontWeight: 500,
              cursor: "pointer",
              "&:hover": {
                color: "#00594f",
              },
            }}
            onClick={handleAddBankAccount}
          >
            <Typography
              sx={{
                fontFamily: "AvenirNext-DemiBold",
                marginLeft: "4px",
              }}
            >
              + Add Another Bank Account
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="body2"
            sx={{
              fontSize: "12px",
              color: "#838588",
            }}
          >
            Adding a Debit Card is optional, but may also be used as a form of
            payment.
          </Typography>
        </Grid>
        {hasDebitCard ? (
          <>
            <Grid item xs={12}>
              <Typography
                variant="h6"
                sx={{
                  fontSize: "12px",
                  fontWeight: "600",
                  paddingLeft: "8px",
                  marginBottom: "5px",
                  color: "#838588",
                }}
              >
                Name on Card
              </Typography>
              <TextField
                variant="outlined"
                value={formData.nameOnCard}
                onChange={(e) => handleCardChange("nameOnCard", e.target.value)}
                fullWidth
                size="small"
                placeholder="Enter Card Holder Name"
                error={!!errors.nameOnCard}
                helperText={errors.nameOnCard}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography
                variant="h6"
                sx={{
                  fontSize: "12px",
                  fontWeight: "600",
                  paddingLeft: "8px",
                  marginBottom: "5px",
                  color: "#838588",
                }}
              >
                Card Number
              </Typography>
              <TextField
                variant="outlined"
                value={formData.cardNumber}
                onChange={(e) => handleCardChange("cardNumber", e.target.value)}
                fullWidth
                size="small"
                placeholder="XXXX-XXXX-XXXX-XXXX"
                error={!!errors.cardNumber}
                helperText={errors.cardNumber}
              />
            </Grid>
            <Grid item xs={3}>
              <Typography
                variant="h6"
                sx={{
                  fontSize: "12px",
                  fontWeight: "600",
                  paddingLeft: "8px",
                  marginBottom: "5px",
                  color: "#838588",
                }}
              >
                Expiration
              </Typography>
              <TextField
                variant="outlined"
                value={formData.expiration}
                onChange={(e) => handleCardChange("expiration", e.target.value)}
                fullWidth
                size="small"
                placeholder="MM/YY"
                error={!!errors.expiration}
                helperText={errors.expiration}
              />
            </Grid>
            <Grid item xs={3}>
              <Typography
                variant="h6"
                sx={{
                  fontSize: "12px",
                  fontWeight: "600",
                  paddingLeft: "8px",
                  marginBottom: "5px",
                  color: "#838588",
                }}
              >
                Security Code
              </Typography>
              <TextField
                variant="outlined"
                value={formData.securityCode}
                onChange={(e) =>
                  handleCardChange("securityCode", e.target.value)
                }
                fullWidth
                size="small"
                placeholder="CVV"
                error={!!errors.securityCode}
                helperText={errors.securityCode}
              />
            </Grid>
            <Grid item xs={12}>
              <RadioGroup
                value={formData?.billingAddressSameAsPrimary}
                onChange={(e) =>
                  handleCardChange(
                    "billingAddressSameAsPrimary",
                    e.target.value === "true"
                  )
                }
                sx={{ display: "flex", alignItems: "start" }}
              >
                <FormControlLabel
                  value={true}
                  sx={{
                    display: "flex",
                    alignItems: "flex-start",
                  }}
                  control={
                    <Radio
                      sx={{
                        color: "#00db8f",
                        padding: "0px 9px !important",
                        "&.Mui-checked": {
                          color: "#00db8f",
                          borderRadius: "50%",
                        },
                      }}
                    />
                  }
                  label={
                    <Typography
                      variant="body2"
                      sx={{
                        fontSize: "12px",
                        color: "#838588",
                        marginBottom: "20px",
                      }}
                    >
                      Yes, my billing address is the same as my mailing address.
                    </Typography>
                  }
                />
                <FormControlLabel
                  value={false}
                  sx={{
                    display: "flex",
                    alignItems: "flex-start",
                  }}
                  control={
                    <Radio
                      sx={{
                        color: "#00db8f",
                        padding: "0px 9px !important",
                        "&.Mui-checked": {
                          color: "#00db8f",
                          borderRadius: "50%",
                        },
                      }}
                    />
                  }
                  label={
                    <Typography
                      variant="body2"
                      sx={{ fontSize: "12px", color: "#838588" }}
                    >
                      No, my billing address is not the same as my mailing
                      address.
                    </Typography>
                  }
                />
              </RadioGroup>
            </Grid>
            {!formData?.billingAddressSameAsPrimary && (
              <AddressForm
                value={formData.billingAddress}
                onChange={handleAddressChange}
                required
                errors={errors.billingAddress}
                addressRequired
                addressLabel="Billing Address"
              />
            )}
            <Grid item xs={12}>
              <Box
                display="flex"
                justifyContent="start"
                alignItems="center"
                sx={{
                  color: "#00db8f",
                  fontWeight: 500,
                  cursor: "pointer",
                  "&:hover": {
                    color: "#00594f",
                  },
                }}
                onClick={handleRemoveDebitCard}
              >
                <ClearIcon fontSize="small" />
                <Typography
                  sx={{
                    fontFamily: "AvenirNext-DemiBold",
                    marginLeft: "4px",
                  }}
                >
                  Remove Debit Card
                </Typography>
              </Box>
            </Grid>
          </>
        ) : (
          <Grid item xs={12}>
            <Box
              display="flex"
              justifyContent="start"
              alignItems="center"
              sx={{
                color: "#00db8f",
                fontWeight: 500,
                cursor: "pointer",
                "&:hover": {
                  color: "#00594f",
                },
              }}
              onClick={handleAddDebitCard}
            >
              <Typography
                sx={{
                  fontFamily: "AvenirNext-DemiBold",
                  marginLeft: "4px",
                }}
              >
                + Add a Debit Card
              </Typography>
            </Box>
          </Grid>
        )}
      </Grid>
      <Box display="flex" justifyContent="center" mt={4}>
        <IconBtn
          icon={<LockIcon sx={{ fontSize: "18px", color: "#00db8f" }} />}
          handleBtnClick={handleNext}
          title="SAVE & CONTINUE"
          type="contained"
        />
      </Box>
      <Box display="flex" justifyContent="center" mt={2}>
        <Button
          variant="text"
          onClick={prevStep}
          sx={{
            color: "#00db8f",
            textTransform: "none",
            fontSize: "16px",
            fontWeight: "600",
          }}
        >
          Go Back
        </Button>
      </Box>
      <SampleCheckModal open={open} handleClose={() => setOpen(false)} />
    </Container>
  );
};

export default StepFour;
