import { Container, Box, Typography, Grid } from "@mui/material";
import { formatDateToDDMMMYYYY } from "../../utils/dateUtils";
import { generateAmountLabel } from "../../utils/utils";

type Props = {
  currentLoanStatus: any;
};

const WithdrawnLoan = (props: Props) => {
  const { currentLoanStatus } = props;

  return (
    <Container maxWidth="md" sx={{ marginTop: "2rem" }}>
      <Box sx={{ textAlign: "left", marginBottom: "2rem" }}>
        <Typography variant="h5" sx={{ color: "#00db8f", fontWeight: "bold" }}>
          Loan Status: {currentLoanStatus?.status}
        </Typography>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Box sx={{ padding: "2rem" }}>
            <Typography
              variant="body2"
              sx={{
                color: "#838588",
                marginBottom: "1rem",
                fontWeight: "bold",
              }}
            >
              Loan Amount
            </Typography>
            <Typography
              variant="h6"
              sx={{ color: "#555658", fontSize: "22px", fontWeight: "400" }}
            >
              {generateAmountLabel(currentLoanStatus?.originalAmount)}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                color: "#838588",
                marginBottom: "2rem",
                fontWeight: "bold",
              }}
            >
              Application Date :{" "}
              {formatDateToDDMMMYYYY(currentLoanStatus?.appliedDate)}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                color: "#838588",
                marginBottom: "1rem",
                fontWeight: "bold",
              }}
            >
              Loan # : {currentLoanStatus?.id}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default WithdrawnLoan;
