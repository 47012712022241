import React from "react";
import BasicProfileInfo from "../../components/basicProfileInfo/BasicProfileInfo";
import { Container, Grid, Typography } from "@mui/material";
import PasswordInfo from "../../components/passwordInfo/PasswordInfo";
import BankAccount from "../../components/bankAccount/BankAccount";
import BankCard from "../../components/bankCard/BankCard";

const MyProfile = () => {
  return (
    <Grid container spacing={2}>
      <Container>
        <Grid item xs={12}>
          <Typography sx={{ fontSize: "12px", color: "#5a5a5b", mb: 1 }}>
            ACCOUNT
          </Typography>
          <Typography
            sx={{ fontSize: "24px", color: "#4caf50", mb: 2, fontWeight: 500 }}
          >
            My Profile
          </Typography>
        </Grid>
      </Container>
      <BasicProfileInfo />
      <PasswordInfo />
      <BankAccount />
      <BankCard />
    </Grid>
  );
};

export default MyProfile;
