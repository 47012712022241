import { AxiosResponse } from "axios";
import axiosInstance from "../core/axios";
import { apiEndPoints } from "../constants/apiEndpoints";
import { Customer } from "../types/genericType";

type CustomerInfo = {
  firstName: string;
  lastName: string;
  ssn: string;
};

export const getCustomerStatus = async () => {
  try {
    const response: AxiosResponse<any> = await axiosInstance.get(
      `${apiEndPoints.currentCustomers}/status`
    );
    return response?.data;
  } catch (err) {
    throw err;
  }
};

export const getCustomerDetails = async () => {
  try {
    const response: AxiosResponse<Customer> = await axiosInstance.get(
      `${apiEndPoints.currentCustomers}`
    );
    return response?.data;
  } catch (err) {
    throw err;
  }
};

export const updateCustomerDetails = async (data: Customer) => {
  try {
    const response: AxiosResponse<any> = await axiosInstance.put(
      `${apiEndPoints.currentCustomers}`,
      data
    );
    return response;
  } catch (err) {
    throw err;
  }
};


export const validateCustomerInfo = async (body: CustomerInfo) => {
  try {
    const response: AxiosResponse<any> = await axiosInstance.post(
      apiEndPoints.validateCustomerInfo,
      body
    );
    return response?.data;
  } catch (err) {
    throw err;
  }
};
